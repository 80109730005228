/**
  Assuming you have use the modals.service (ng2/shared/modals/modals.service.ts)
  to define an initial modal config object, all modals will be wrapped in `nv-mat-dialog`.

  Styles for `nv-mat-dialog` are defined in custom-material.scss (ng2/styles/custom-material.scss).
  Going forward if you need to override any angular material elements,
  like `mat-form-field` or other, try to do so in that file, NOT in this file.

  This file should be reserved for defining rulesets for dialogs/modals
  when just overriding angular material elements is not sufficient.

  CAVEAT: If UI design calls for modal specific tweaks to an angular material element
  we can do that here. Meaning, if the student report modal needs to have a
  slightly different implementation of `mat-form-field` than what is defined for all of the other
  modals, it is OK to define that here.

  - JYR

  At the end of this file, styling for .nv-dialog is placed there TEMPORALLY.
  It is  been used by add-college, add-career, alert and list-summary modal.
  TODO: we need to dry them up further and refactor their templates/components structure to inherit from Jamie's base modal architecture

  - JChu
*/

@use '../../../../projects/shared/nvps-libraries/design/styles/nv-typography/nv-typography.mixin.scss' as *;
@import '../../../../projects/shared/styles/variable-components.scss';

// TODO: move to base-modal.scss when able
.base-modal-shell {
  box-sizing: border-box;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'actions';
  grid-template-rows: 1fr max-content minmax(1fr max-content);
  grid-auto-flow: row;
  grid-row-gap: var(--space-m);
  color: var(--color-primary-text);
  border-radius: var(--modal-border-radius);
  background: var(--color-white);
  border-radius: var(--space-xxs);

  // common to all dialog-content containers
  p {
    margin: 0;
    padding-left: var(--space-xs);
    color: var(--color-primary-text);
  }

  // TODO - remove this when all modals use nv-form-field
  label {
    @include nv-font-body-3;
    margin-bottom: var(--space-xs);
    display: inline-block;
    width: 100%;
  }
  // Undo the styling done above
  nv-form-field label, mat-checkbox label, mat-radio-button label {
    display: unset;
    width: unset;
    margin-bottom: unset;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    letter-spacing: inherit;
    opacity: unset;
  }
  
  // TODO: this is parent to ng-content. Add child class for each modal variant as variants
  .dialog-body {
    label {
      color: var(--color-primary-text);
    }
  }
  
  .dialog-header {
    .dialog-title {
      @include nv-font-header-2; // typography
    }

    .close-button-right {
      display: flex;
      justify-content: space-between;
    }
    .arrow-button-left {
      display: flex;
      align-items: center;
    }
    .dialog-subtitle {
      @include nv-font-subtitle-3; // typography
      opacity: var(--color-opacity-50);
    }
  } 

  // TODO in some modal variants, the markup for this might live inside dialog-body.
  .dialog-actions {
    grid-area: actions;
    grid-auto-flow: column;
    align-self: flex-end;
    text-align: right;
    justify-content: flex-end;
    grid-column-gap: var(--space-s);
    padding: 0;
    margin-bottom: -24px; // Default margin prior to material v15
  }

  .mat-mdc-dialog-content {
    padding: 0;
  }
}


/*
  component: mat-dialog

  `
    this.dialog.open(CustomModalComponent, {
      panelClass: 'nv-mat-dialog'
    });
  `
*/
.nv-mat-dialog {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-l);
      width: 500px;
      border-radius: var(--modal-border-radius);
    }
  }
}

.nv-mat-dialog-sm {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-l);
      width: 250px;
      border-radius: var(--modal-border-radius);
    }
  }
}

.nv-mat-dialog-med {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-l);
      width: 600px;
      border-radius: var(--modal-border-radius);
    }
  }
}

.nv-mat-dialog-med-no-padding {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: 0;
      width: 600px;
      border-radius: var(--modal-border-radius);
    }
  }
}

.nv-mat-dialog-no-padding-no-overflow-wide {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: 0;
      width: 800px;
      max-height: 90vh;
      min-width: 700px;
      min-height: 90vh;
      overflow: hidden;
      border-radius: var(--modal-border-radius);
    }
  }
}

.nv-mat-dialog-wide {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-l);
      width: 800px;
      border-radius: var(--modal-border-radius);
    }
  }
}

.nv-mat-dialog-wide-tall {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-l);
      width: 800px;
      height: 600px;
      border-radius: var(--modal-border-radius);
    }
  }
}

.nv-mat-dialog-extra-wide {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-xxl);
      width: 1000px;
      border-radius: var(--modal-border-radius);
      max-height: 95vh;
    }
  }
}

.nv-mat-dialog-wide-no-padding {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: 0;
      width: 800px;
      max-height: 90vh;
      min-width: 700px;
      min-height: 500px;
      border-radius: var(--modal-border-radius);
    }
  }
}

// define styles for dialogs that are configured with this panelClass. See modals.service for examples of methods that
// open an instance of the modal service using a configuration that contains `nv-mat-dialog-common-markup` - JYR
.nv-mat-dialog-common-markup {
  mat-dialog-container {
    .mdc-dialog__surface {
      padding: var(--space-l);
      overflow: hidden;
      width: 500px;
      overflow-y: auto;
      max-height: 95vh;
      border-radius: var(--modal-border-radius);
    }
  }

  mat-dialog-content {
    width: 100%;
    overflow: visible;
  }

  mat-form-field {
    @extend %nv-mat-form-field;
    .mat-form-field-infix {
      // display: inline-block;
      // background-color: var(--color-blue-03);
      // border: var(--space-xxxs) solid var(--color-blue-10);
      // border-radius: var(--space-xs);
      // padding: var(--space-s);
      border: 0;
      padding: 0;
    }

    .mat-mdc-input-element {
      @extend %nv-textbox-search;
      // color: var(--color-blue);
      // font-size: var(--font-s);
      // margin: var(--space-xs) 0;
      // padding: 0;
      // position: relative;
      width: 100%;
      box-sizing: border-box;
      height: var(--space-xxl);
      // display: inline-block;
    }

    .mat-form-field-ripple {
      background-color: transparent;
    }
  }

  mat-dialog-actions {
    // margin-bottom: 0;
    margin-top: var(--space-m);
    padding-top: 0;
    padding-bottom: 0;
  }
}


.nv-dialog {
  background: white;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: var(--space-m);
  grid-template-areas: 'title' 'message' 'controls';
  grid-template-rows: minmax(var(--space-xxl), auto) 1fr auto;
  border-radius: var(--space-xxs);
  box-sizing: border-box;
  overflow: auto;
  color: var(--color-blue);

  .dialog-head {
    grid-area: title;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: var(--space-m);
    > div.icon,
    > h2 {
      align-self: center;
    }
  }

  .dialog-message {
    grid-area: message;
    display: grid;
    grid-auto-flow: row;
    align-content: center;
    grid-row-gap: var(--space-m);
  }

  .dialog-controls {
    display: grid;
    grid-area: controls;
    grid-auto-flow: column;
    align-self: flex-end;
    text-align: right;
    justify-content: flex-end;
    grid-column-gap: var(--space-s);
  }

  .mat-mdc-form-field {
    width: 436px;

    .mat-form-field-label {
      position: relative;
      margin-left: 43px;
      color: var(--color-blue-50);
    }

    &.mat-focused .mat-form-field-label {
      color: transparent;
    }
  }

  .mdc-line-ripple {
    height: 0;
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  .mat-mdc-input-element {
    @extend %nv-textbox-search;
    width: 86%;
  }
}

// custom styling for nv-design components rendering in modals
// TODO: see if this should be moved once the design library is back in this repo (JE)

.cdk-overlay-container {
  .nv-multi-picker-menu {
    max-height: calc(50vh - 48px);
    width: 410px;
    max-width: 410px;

    .mat-mdc-menu-content {
      width: 390px;
    }

    .searchbox {
      position: absolute;
      z-index: var(--z-idx-elevated);
      top: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: white;
      width: 390px;

      nv-icon {
        top: 8px;
      }
    }

    .option-pills {
      margin-top: 42px;
      width: 390px;
    }
  }
}