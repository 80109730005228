// Constants taken from Angular Material for use in our own SASS
$baseline-grid: 8px;
$md-toolbar-height: 64px;
$scrollbar-width: 15px; // This is the default on most desktop browsers
$md-toolbar-min-height: 64px;

// Custom toolbar heights
// Use by adding a class to the toolbar like this: <md-toolbar class="md-medium"><md-toolbar>
$md-toolbar-medium-height: 7 * $baseline-grid;
$md-toolbar-small-height: 6 * $baseline-grid;
$md-toolbar-extra-small-height: 5 * $baseline-grid;
$md-toolbar-extra-extra-small-height: 4 * $baseline-grid;
