list {
  section {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-rows: var(--space-xxxl);
  }

  .list-section-wrap {
    overflow-y: overlay;
    overflow-x: hidden;
    padding-left: 21px;
    padding-right: var(--scroll-bar);
  }

  .list-empty {
    padding-left: 35px;
  }

  // Note - jchu - nv-checkbox-wrapper is deprecated, please use nv-mat-checkbox
  .nv-checkbox-wrapper {
    margin-left: 0;
  }

  .cell-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  listhead {
    display: grid;
    grid-template-columns: auto;
    grid-row: auto / span 2;
    transition: box-shadow 0.5s;

    &.first-header {
      padding-right: var(--scroll-bar);
      padding-left: 21px;
      padding-bottom: 6px;
    }

    &.shadow {
      box-shadow: var(--elevation-2);
      z-index: var(--z-idx-1);
    }
    align-content: flex-end;

    cell {
      align-self: flex-end;
      grid-auto-flow: column;
      height: var(--space-xxxl);
      text-align: center;
      font-size: var(--font-xxs);
      font-weight: var(--font-weight-bold);

      &.first {
        text-transform: none;
        letter-spacing: normal;
        font-size: var(--font-s);
        span {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 var(--space-m) 0 0;
        }
      }
    }
  }

  cell {
    display: grid;
    grid-auto-columns: auto;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    cursor: pointer;
    background-size: auto var(--space-l);
    overflow: hidden;
    padding: 0px var(--space-s);
    min-height: var(--space-xl);
    max-height: calc(var(--space-xxxl) - var(--space-xxxs));
    margin-top: var(--space-xxxs);
    position: relative;
    color: var(--color-grey-dark-7);
    font-family: var(--font-family-normal);
    font-size: var(--font-xs);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: normal; 

    &.center {
      justify-content: center;
      text-align: center;
    }

    &.em-dash {
      color: var(--color-primary-text);
    }

    .cell-sleeve {
      .label {
        justify-self: center;
      }
      .sort-arrow {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: var(--space-xxs);
        color: var(--color-purple);
        font-weight: bold;
        font-size: var(--font-xxxs);
      }
    }

    .batch-edit-mode-disabled-fields {
      opacity: 0.3;
      z-index: var(--z-idx-elevated);
      background: white;
      height: 46px;
      line-height: var(--space-xxxl);
      display: block;
      pointer-events: none;
    }

    &.flags {
      background-repeat: no-repeat;
      background-position: center center;
      height: 50px;
      &.one-flag {
        background: url(/assets/v3/img/icon-flags-1.png) no-repeat center;
      }

      &.two-flags {
        background: url(/assets/v3/img/icon-flags-2.png) no-repeat center;
      }

      &.three-plus-flags {
        background: url(/assets/v3/img/icon-flags-3.png) no-repeat center;
      }

      span {
        width: var(--space-xxxl);
        &.batch-edit-mode-disabled-fields {
          opacity: 0.7;
          width: 200px;

          &:hover {
            background: none;
          }
        }
      }
    }
  }

  cell.center span.em-dash {
    color: var(--color-primary-text);
  }

  .sort-arrow {
    font-size: var(--font-xxs);
  }

  .fixed-up::after,
  .hover-up::after {
    content: '↑';
  }

  .fixed-down::after,
  .hover-down::after {
    content: '↓';
  }

  row {
    height: calc(var(--space-xxxl) - var(--space-xxxs));
    display: grid;
    grid-template-columns: var(--space-xxxl) 3fr 170px 170px;
    border-bottom: var(--space-xxxs) solid var(--color-grey-light-7);
    cursor: pointer;
    &:hover {
      background: var(--color-blue-light-5);
    }
    background: none;

    &.s1 {
      grid-template-columns: 3fr 120px 120px;
    }
  }

  &.list-my-students {
    listhead.s1,
    row.s1 {
      grid-auto-flow: column;
      grid-auto-columns: minmax(auto, 120px);
      grid-template-columns: minmax(0, max-content) 1fr 120px;
      cell:nth-child(2) {
        padding-left: 0px;
      }
      &.batch-edit-mode {
        grid-template-columns: var(--space-xxxl) 1fr 120px 120px;
      }
    }
  }

  &.graph-detail {
    listhead.s1,
    row.s1 {
      grid-auto-flow: column;
      grid-template-columns: auto 120px;
      grid-auto-columns: minmax(0px, 120px);
    }
  }

  &.graph-list {
    listhead.s1,
    row.s1 {
      grid-auto-flow: column;
      grid-auto-columns: minmax(0px, 120px);
      grid-template-columns: minmax(0, max-content) 1fr 100px;
      cell:nth-child(2) {
        padding-left: 0px;
      }
    }
  }

  &.student-level {
    listhead.s1,
    row.s1 {
      grid-auto-flow: column;
      grid-template-columns: var(--space-s) 3fr;
      grid-auto-columns: 1fr;
      &.batch-edit-mode {
        grid-template-columns: var(--space-xl) minmax(calc(33% - 48px), 3fr);
        &.batch-edit-mode-checked {
          background-color: var(--color-blue-5);
        }
        &.batch-edit-mode-no-section-checkbox {
          grid-template-columns: minmax(33%, 3.2fr);
        }
      }
    }
    listhead,
    row {
      cell:nth-child(2) {
        padding-left: 0px;
      }
    }

    .warning {
      color: var(--color-pink);
    }
  }

  &.list-attendance {
    listhead.s1,
    row.s1 {
      grid-auto-flow: column;
      grid-auto-columns: minmax(auto, 110px);
      grid-template-columns: minmax(0, max-content) 1fr 110px;
      cell:nth-child(2) {
        padding-left: 0px;
      }
      &.batch-edit-mode {
        grid-template-columns: var(--space-xxxl) 1fr 120px 120px;
      }
    }
  }

  &.list-academic {
    listhead.s1,
    row.s1 {
      grid-auto-flow: column;
      grid-auto-columns: minmax(auto, 120px);
      grid-template-columns: minmax(0, max-content) 1fr 110px;

      cell:nth-child(2) {
        padding-left: 0px;
      }
      &.batch-edit-mode {
        grid-template-columns: var(--space-xxxl) 1fr 120px 120px;
      }
    }
    .warning {
      background-color: var(--color-pink-20);
    }

    .more-info {
      background-repeat: no-repeat;
      background-position: center center;
      height: 50px;
      background: url(/assets/v3/img/generate_report@1x.png) no-repeat center;
      span {
        width: var(--space-xxxl);
        &.batch-edit-mode-disabled-fields {
          opacity: 0.7;
          width: 200px;

          &:hover {
            background: none;
          }
        }
      }
    }
  }

  &.list-postsecondary {
    listhead.s1,
    row.s1 {
      grid-auto-flow: column;
      grid-template-columns: minmax(0, max-content) 1fr 30px;
      grid-column-start: auto;
      &.batch-edit-mode {
        grid-template-columns: var(--space-xxxl) 1fr auto;
      }

      cell {
        &:nth-child(2) {
          padding-left: 0px;
        }
        + .center {
          grid-auto-columns: minmax(auto, 14px);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 60%;
        }

        + .milestone-complete {
          background-image: url(/assets/v3/img/icon-milestone-complete.png);
        }

        + .milestone-past-due {
          background-image: url(/assets/v3/img/icon-milestone-pastdue.png);
        }

        + .milestone-due-soon {
          background-image: url(/assets/v3/img/icon-milestone-due-soon.png);
        }

        + .milestone-not-due-yet {
          background-image: url(/assets/v3/img/icon-milestone-not-due-yet.png);
        }
      }
    }
  }

  &.list-postsecondary-alt,
  &.graph-list-post-sec {
    listhead.s1,
    row.s1 {
      grid-auto-flow: column;
      grid-template-columns: minmax(0, max-content) 1fr 90px;
      grid-column-start: auto;
      &.batch-edit-mode {
        grid-template-columns: var(--space-xxxl) 1fr auto;
      }

      cell {
        &:nth-child(2) {
          padding-left: 0px;
        }
        + .center {
          grid-auto-columns: minmax(auto, 85px);
        }
      }
    }

    row.s1 cell {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        padding: 0 7px;
      }
      &:nth-child(2) {
        span {
          padding: 0;
        }
      }
    }
  }

  .disabled {
    color: rgba(10, 76, 207, 0.5);
  }

  .person-initials {
    display: inline-block;
    padding: var(--space-xxs) var(--space-m);
    border-radius: var(--space-l);
    background: var(--color-blue-05);
  }

  listfoot {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    align-self: stretch;
    background-size: auto var(--space-l);
    overflow: hidden;
    padding: 0px var(--space-s);
    min-height: var(--space-xl);
    &:hover {
      background: var(--color-blue-05);
    }
    &.list-foot-fixed-table {
      grid-template-columns: var(--space-xxxl) auto;
      padding: 0;
    }
  }
}

.list-header-col-tooltip {
  margin-top: var(--space-m);
  margin-left: var(--space-m);
  word-wrap: break-word;
  &.list-header-col-tooltip-long {
    width: 180px;
  }
  &.postsec-list-header-col-tooltip {
    // only applied to col header tooltip on postsec list focused on career/college milestones (JChu)
    width: auto;
  }
}

.group-label-tooltip {
  margin-bottom: 5px;
  margin-left: var(--space-m);
}

.point-person-tooltip {
  white-space: pre-line;
  justify-content: center;
  text-align: center;
  width: max-content;
}

.path-status-tooltip {
  white-space: normal;
  justify-content: center;
  text-align: left;
  width: max-content;
  line-height: normal;

  span {
    display: block;
    line-height: normal;
    text-align: left;
  }

  span:nth-child(2) {
    color: var(--color-purple);
    font-size: var(--font-xxs);
    font-family: var(--font-family-mono);
    letter-spacing: calc(var(--space-xxxs) * -1);
  }
}

.milestone-tooltip,
.activity-tooltip {
  white-space: normal;
  justify-content: center;
  text-align: left;
  width: max-content;
  line-height: normal;
  font-weight: normal;

  span {
    display: block;
    line-height: normal;
    text-align: left;
  }
}
.financial-aid-tooltip {
  white-space: normal;
  justify-content: center;
  text-align: left;
  width: max-content;
  line-height: normal;

  span {
    display: block;
    line-height: normal;
    text-align: left;
  }

  span:nth-child(2) {
    color: var(--color-purple);
    font-size: var(--font-xxs);
    font-family: var(--font-family-mono);
    letter-spacing: calc(var(--space-xxxs) * -1);
  }
}

.tooltip-wrapper {
  line-height: 20px;
  .tooltip-pill {
    background-color: var(--color-blue-10);
    border-radius: 12px;
    padding: 6px;
    margin-right: 4px;
  }
}

.nv-mat-tooltip-panel {
  .more-info-wide {
    width: auto;
  }
}

