@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap&subset=latin-ext");
list section {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: var(--space-xxxl);
}
list .list-section-wrap {
  overflow-y: overlay;
  overflow-x: hidden;
  padding-left: 21px;
  padding-right: var(--scroll-bar);
}
list .list-empty {
  padding-left: 35px;
}
list .nv-checkbox-wrapper {
  margin-left: 0;
}
list .cell-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
list listhead {
  display: grid;
  grid-template-columns: auto;
  grid-row: auto/span 2;
  transition: box-shadow 0.5s;
  align-content: flex-end;
}
list listhead.first-header {
  padding-right: var(--scroll-bar);
  padding-left: 21px;
  padding-bottom: 6px;
}
list listhead.shadow {
  box-shadow: var(--elevation-2);
  z-index: var(--z-idx-1);
}
list listhead cell {
  align-self: flex-end;
  grid-auto-flow: column;
  height: var(--space-xxxl);
  text-align: center;
  font-size: var(--font-xxs);
  font-weight: var(--font-weight-bold);
}
list listhead cell.first {
  text-transform: none;
  letter-spacing: normal;
  font-size: var(--font-s);
}
list listhead cell.first span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 var(--space-m) 0 0;
}
list cell {
  display: grid;
  grid-auto-columns: auto;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  cursor: pointer;
  background-size: auto var(--space-l);
  overflow: hidden;
  padding: 0px var(--space-s);
  min-height: var(--space-xl);
  max-height: calc(var(--space-xxxl) - var(--space-xxxs));
  margin-top: var(--space-xxxs);
  position: relative;
  color: var(--color-grey-dark-7);
  font-family: var(--font-family-normal);
  font-size: var(--font-xs);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  line-height: normal;
}
list cell.center {
  justify-content: center;
  text-align: center;
}
list cell.em-dash {
  color: var(--color-primary-text);
}
list cell .cell-sleeve .label {
  justify-self: center;
}
list cell .cell-sleeve .sort-arrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: var(--space-xxs);
  color: var(--color-purple);
  font-weight: bold;
  font-size: var(--font-xxxs);
}
list cell .batch-edit-mode-disabled-fields {
  opacity: 0.3;
  z-index: var(--z-idx-elevated);
  background: white;
  height: 46px;
  line-height: var(--space-xxxl);
  display: block;
  pointer-events: none;
}
list cell.flags {
  background-repeat: no-repeat;
  background-position: center center;
  height: 50px;
}
list cell.flags.one-flag {
  background: url(/assets/v3/img/icon-flags-1.png) no-repeat center;
}
list cell.flags.two-flags {
  background: url(/assets/v3/img/icon-flags-2.png) no-repeat center;
}
list cell.flags.three-plus-flags {
  background: url(/assets/v3/img/icon-flags-3.png) no-repeat center;
}
list cell.flags span {
  width: var(--space-xxxl);
}
list cell.flags span.batch-edit-mode-disabled-fields {
  opacity: 0.7;
  width: 200px;
}
list cell.flags span.batch-edit-mode-disabled-fields:hover {
  background: none;
}
list cell.center span.em-dash {
  color: var(--color-primary-text);
}
list .sort-arrow {
  font-size: var(--font-xxs);
}
list .fixed-up::after,
list .hover-up::after {
  content: "↑";
}
list .fixed-down::after,
list .hover-down::after {
  content: "↓";
}
list row {
  height: calc(var(--space-xxxl) - var(--space-xxxs));
  display: grid;
  grid-template-columns: var(--space-xxxl) 3fr 170px 170px;
  border-bottom: var(--space-xxxs) solid var(--color-grey-light-7);
  cursor: pointer;
  background: none;
}
list row:hover {
  background: var(--color-blue-light-5);
}
list row.s1 {
  grid-template-columns: 3fr 120px 120px;
}
list.list-my-students listhead.s1,
list.list-my-students row.s1 {
  grid-auto-flow: column;
  grid-auto-columns: minmax(auto, 120px);
  grid-template-columns: minmax(0, max-content) 1fr 120px;
}
list.list-my-students listhead.s1 cell:nth-child(2),
list.list-my-students row.s1 cell:nth-child(2) {
  padding-left: 0px;
}
list.list-my-students listhead.s1.batch-edit-mode,
list.list-my-students row.s1.batch-edit-mode {
  grid-template-columns: var(--space-xxxl) 1fr 120px 120px;
}
list.graph-detail listhead.s1,
list.graph-detail row.s1 {
  grid-auto-flow: column;
  grid-template-columns: auto 120px;
  grid-auto-columns: minmax(0px, 120px);
}
list.graph-list listhead.s1,
list.graph-list row.s1 {
  grid-auto-flow: column;
  grid-auto-columns: minmax(0px, 120px);
  grid-template-columns: minmax(0, max-content) 1fr 100px;
}
list.graph-list listhead.s1 cell:nth-child(2),
list.graph-list row.s1 cell:nth-child(2) {
  padding-left: 0px;
}
list.student-level listhead.s1,
list.student-level row.s1 {
  grid-auto-flow: column;
  grid-template-columns: var(--space-s) 3fr;
  grid-auto-columns: 1fr;
}
list.student-level listhead.s1.batch-edit-mode,
list.student-level row.s1.batch-edit-mode {
  grid-template-columns: var(--space-xl) minmax(calc(33% - 48px), 3fr);
}
list.student-level listhead.s1.batch-edit-mode.batch-edit-mode-checked,
list.student-level row.s1.batch-edit-mode.batch-edit-mode-checked {
  background-color: var(--color-blue-5);
}
list.student-level listhead.s1.batch-edit-mode.batch-edit-mode-no-section-checkbox,
list.student-level row.s1.batch-edit-mode.batch-edit-mode-no-section-checkbox {
  grid-template-columns: minmax(33%, 3.2fr);
}
list.student-level listhead cell:nth-child(2),
list.student-level row cell:nth-child(2) {
  padding-left: 0px;
}
list.student-level .warning {
  color: var(--color-pink);
}
list.list-attendance listhead.s1,
list.list-attendance row.s1 {
  grid-auto-flow: column;
  grid-auto-columns: minmax(auto, 110px);
  grid-template-columns: minmax(0, max-content) 1fr 110px;
}
list.list-attendance listhead.s1 cell:nth-child(2),
list.list-attendance row.s1 cell:nth-child(2) {
  padding-left: 0px;
}
list.list-attendance listhead.s1.batch-edit-mode,
list.list-attendance row.s1.batch-edit-mode {
  grid-template-columns: var(--space-xxxl) 1fr 120px 120px;
}
list.list-academic listhead.s1,
list.list-academic row.s1 {
  grid-auto-flow: column;
  grid-auto-columns: minmax(auto, 120px);
  grid-template-columns: minmax(0, max-content) 1fr 110px;
}
list.list-academic listhead.s1 cell:nth-child(2),
list.list-academic row.s1 cell:nth-child(2) {
  padding-left: 0px;
}
list.list-academic listhead.s1.batch-edit-mode,
list.list-academic row.s1.batch-edit-mode {
  grid-template-columns: var(--space-xxxl) 1fr 120px 120px;
}
list.list-academic .warning {
  background-color: var(--color-pink-20);
}
list.list-academic .more-info {
  background-repeat: no-repeat;
  background-position: center center;
  height: 50px;
  background: url(/assets/v3/img/generate_report@1x.png) no-repeat center;
}
list.list-academic .more-info span {
  width: var(--space-xxxl);
}
list.list-academic .more-info span.batch-edit-mode-disabled-fields {
  opacity: 0.7;
  width: 200px;
}
list.list-academic .more-info span.batch-edit-mode-disabled-fields:hover {
  background: none;
}
list.list-postsecondary listhead.s1,
list.list-postsecondary row.s1 {
  grid-auto-flow: column;
  grid-template-columns: minmax(0, max-content) 1fr 30px;
  grid-column-start: auto;
}
list.list-postsecondary listhead.s1.batch-edit-mode,
list.list-postsecondary row.s1.batch-edit-mode {
  grid-template-columns: var(--space-xxxl) 1fr auto;
}
list.list-postsecondary listhead.s1 cell:nth-child(2),
list.list-postsecondary row.s1 cell:nth-child(2) {
  padding-left: 0px;
}
list.list-postsecondary listhead.s1 cell + .center,
list.list-postsecondary row.s1 cell + .center {
  grid-auto-columns: minmax(auto, 14px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}
list.list-postsecondary listhead.s1 cell + .milestone-complete,
list.list-postsecondary row.s1 cell + .milestone-complete {
  background-image: url(/assets/v3/img/icon-milestone-complete.png);
}
list.list-postsecondary listhead.s1 cell + .milestone-past-due,
list.list-postsecondary row.s1 cell + .milestone-past-due {
  background-image: url(/assets/v3/img/icon-milestone-pastdue.png);
}
list.list-postsecondary listhead.s1 cell + .milestone-due-soon,
list.list-postsecondary row.s1 cell + .milestone-due-soon {
  background-image: url(/assets/v3/img/icon-milestone-due-soon.png);
}
list.list-postsecondary listhead.s1 cell + .milestone-not-due-yet,
list.list-postsecondary row.s1 cell + .milestone-not-due-yet {
  background-image: url(/assets/v3/img/icon-milestone-not-due-yet.png);
}
list.list-postsecondary-alt listhead.s1,
list.list-postsecondary-alt row.s1, list.graph-list-post-sec listhead.s1,
list.graph-list-post-sec row.s1 {
  grid-auto-flow: column;
  grid-template-columns: minmax(0, max-content) 1fr 90px;
  grid-column-start: auto;
}
list.list-postsecondary-alt listhead.s1.batch-edit-mode,
list.list-postsecondary-alt row.s1.batch-edit-mode, list.graph-list-post-sec listhead.s1.batch-edit-mode,
list.graph-list-post-sec row.s1.batch-edit-mode {
  grid-template-columns: var(--space-xxxl) 1fr auto;
}
list.list-postsecondary-alt listhead.s1 cell:nth-child(2),
list.list-postsecondary-alt row.s1 cell:nth-child(2), list.graph-list-post-sec listhead.s1 cell:nth-child(2),
list.graph-list-post-sec row.s1 cell:nth-child(2) {
  padding-left: 0px;
}
list.list-postsecondary-alt listhead.s1 cell + .center,
list.list-postsecondary-alt row.s1 cell + .center, list.graph-list-post-sec listhead.s1 cell + .center,
list.graph-list-post-sec row.s1 cell + .center {
  grid-auto-columns: minmax(auto, 85px);
}
list.list-postsecondary-alt row.s1 cell span, list.graph-list-post-sec row.s1 cell span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding: 0 7px;
}
list.list-postsecondary-alt row.s1 cell:nth-child(2) span, list.graph-list-post-sec row.s1 cell:nth-child(2) span {
  padding: 0;
}
list .disabled {
  color: rgba(10, 76, 207, 0.5);
}
list .person-initials {
  display: inline-block;
  padding: var(--space-xxs) var(--space-m);
  border-radius: var(--space-l);
  background: var(--color-blue-05);
}
list listfoot {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  align-self: stretch;
  background-size: auto var(--space-l);
  overflow: hidden;
  padding: 0px var(--space-s);
  min-height: var(--space-xl);
}
list listfoot:hover {
  background: var(--color-blue-05);
}
list listfoot.list-foot-fixed-table {
  grid-template-columns: var(--space-xxxl) auto;
  padding: 0;
}

.list-header-col-tooltip {
  margin-top: var(--space-m);
  margin-left: var(--space-m);
  word-wrap: break-word;
}
.list-header-col-tooltip.list-header-col-tooltip-long {
  width: 180px;
}
.list-header-col-tooltip.postsec-list-header-col-tooltip {
  width: auto;
}

.group-label-tooltip {
  margin-bottom: 5px;
  margin-left: var(--space-m);
}

.point-person-tooltip {
  white-space: pre-line;
  justify-content: center;
  text-align: center;
  width: max-content;
}

.path-status-tooltip {
  white-space: normal;
  justify-content: center;
  text-align: left;
  width: max-content;
  line-height: normal;
}
.path-status-tooltip span {
  display: block;
  line-height: normal;
  text-align: left;
}
.path-status-tooltip span:nth-child(2) {
  color: var(--color-purple);
  font-size: var(--font-xxs);
  font-family: var(--font-family-mono);
  letter-spacing: calc(var(--space-xxxs) * -1);
}

.milestone-tooltip,
.activity-tooltip {
  white-space: normal;
  justify-content: center;
  text-align: left;
  width: max-content;
  line-height: normal;
  font-weight: normal;
}
.milestone-tooltip span,
.activity-tooltip span {
  display: block;
  line-height: normal;
  text-align: left;
}

.financial-aid-tooltip {
  white-space: normal;
  justify-content: center;
  text-align: left;
  width: max-content;
  line-height: normal;
}
.financial-aid-tooltip span {
  display: block;
  line-height: normal;
  text-align: left;
}
.financial-aid-tooltip span:nth-child(2) {
  color: var(--color-purple);
  font-size: var(--font-xxs);
  font-family: var(--font-family-mono);
  letter-spacing: calc(var(--space-xxxs) * -1);
}

.tooltip-wrapper {
  line-height: 20px;
}
.tooltip-wrapper .tooltip-pill {
  background-color: var(--color-blue-10);
  border-radius: 12px;
  padding: 6px;
  margin-right: 4px;
}

.nv-mat-tooltip-panel .more-info-wide {
  width: auto;
}

div.nv-table-container {
  overflow: auto;
}

table.nv-table {
  width: 100%;
  border-spacing: 0;
  overflow: hidden;
}
table.nv-table tr {
  height: 48px;
  transition: background-color 0s;
  background-color: inherit;
}
table.nv-table td {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
table.nv-table th {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 5px;
  text-align: left;
}
table.nv-table th.col-header-button {
  cursor: pointer;
}
table.nv-table th:focus {
  outline: none !important;
}
table.nv-table th span:focus {
  outline: none !important;
}
table.nv-table th span.sort-direction {
  color: rgba(0, 0, 0, 0.87);
  font-size: 75%;
}
table.nv-table th span.sort-direction:after {
  content: "▲";
}
table.nv-table th span.sort-direction.reverse-sort:after {
  content: "▼";
}
table.nv-table th span.active-sort {
  color: rgba(0, 0, 0, 0.87);
}
table.nv-table th,
table.nv-table td {
  color: var(--color-primary-text);
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
}
table.nv-table th.left-align,
table.nv-table td.left-align {
  text-align: left !important;
}
table.nv-table th.right-align,
table.nv-table td.right-align {
  text-align: right !important;
}
table.nv-table th.center-align,
table.nv-table td.center-align {
  text-align: center !important;
}
table.nv-table th.number-col,
table.nv-table td.number-col {
  text-align: center !important;
}
table.nv-table th.score-col,
table.nv-table td.score-col {
  font-size: 13px;
  font-weight: 500;
}
table.nv-table tbody tr:not([disabled]):hover {
  background: #e4e6e9;
}

table.nv-table-dense tr {
  height: 32px;
}

table.nv-table-extra-dense tr {
  height: 28px;
}

table.nv-data-table td {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
}
table.nv-data-table th {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 5px;
}
table.nv-data-table th.col-header-button {
  cursor: pointer;
}
table.nv-data-table th span:focus {
  outline: none !important;
}
table.nv-data-table th span.sort-direction {
  color: rgba(0, 0, 0, 0.87);
  font-size: 75%;
}
table.nv-data-table th span.sort-direction:after {
  content: "▲";
}
table.nv-data-table th span.sort-direction.reverse-sort:after {
  content: "▼";
}
table.nv-data-table th span.active-sort {
  color: rgba(0, 0, 0, 0.87);
}
table.nv-data-table th.double-header {
  color: rgba(0, 0, 0, 0.87);
}
table.nv-data-table th,
table.nv-data-table td {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  vertical-align: middle;
}
table.nv-data-table th.left-align,
table.nv-data-table td.left-align {
  text-align: left !important;
}
table.nv-data-table th.right-align,
table.nv-data-table td.right-align {
  text-align: right !important;
}
table.nv-data-table th.center-align,
table.nv-data-table td.center-align {
  text-align: center !important;
}
table.nv-data-table th.number-col,
table.nv-data-table td.number-col {
  text-align: center !important;
}
table.nv-data-table th.score-col,
table.nv-data-table td.score-col {
  font-size: 13px;
  font-weight: 500;
}
table.nv-data-table td.md-cell.md-numeric,
table.nv-data-table th.md-column.md-numeric {
  text-align: center;
}
table.nv-data-table tbody tr:not([disabled]):hover {
  background-color: var(--color-blue-light-5);
}

table.nv-data-table-dense tr {
  height: 32px !important;
}

table.nv-data-table-extra-dense tr {
  height: 28px !important;
}

/**
  * Global Typography mixin utilities
  * contains mixin utilities for typography
  * 
*/
/**
  * Global Typography mixin
  * mixin can be provided with two optional args
  * 
  * arg `$color` - color of the text, use color variable, default is grey-dark-9
  * arg `$fontStyle` - font style of the text, either normal or italic, default is normal
  *
  * Updates the following properties:
  * - font-size, letter-spacing, line-height
  * - font-family, font-style, font-weight
  * - text-transform
  * - font-color
  * 
  * Usage example:
  * .a-button-class {
  *   @include nv-font-button('blue', italic);
  * }
  * 
  * .a-caption-class {
  *   @include nv-font-caption-1('grey-dark-9');
  * }
  * 
  * .a-body-class {
  *   @include nv-font-body-2(null, italic);
  * }
  * 
  * .a-title-class {
  *   @include nv-font-header-1();
  * }
  * 
*/
/* 

We should not have global styles that are component specific defined here.
Either move your styles to a more specific component,
or remove them from this repo, and use them in our design library "src/nvps-libraries/design"
- AT

*/
.nv-checkbox-wrapper {
  padding: 0;
  justify-content: center;
  margin-left: var(--space-xs);
}
.nv-checkbox-wrapper .checkbox {
  height: var(--space-l);
  width: var(--space-l);
  display: inline-block;
  position: relative;
  border-radius: var(--space-xxs);
  background-image: url(/assets/v3/img/checkbox-off.png);
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}
.nv-checkbox-wrapper .checkbox.checkbox-selected {
  background-image: url(/assets/v3/img/checkbox-on.png);
}

.nv-mat-autocomplete mat-form-field, .nv-mat-dialog-common-markup mat-form-field {
  width: 100%;
}
.nv-mat-autocomplete mat-form-field .mdc-line-ripple, .nv-mat-dialog-common-markup mat-form-field .mdc-line-ripple {
  display: none;
}
.nv-mat-autocomplete mat-form-field .mat-form-field-label, .nv-mat-dialog-common-markup mat-form-field .mat-form-field-label {
  color: var(--color-blue-50);
}
.nv-mat-autocomplete mat-form-field.mat-form-field-invalid .mat-mdc-input-element, .nv-mat-dialog-common-markup mat-form-field.mat-form-field-invalid .mat-mdc-input-element, .nv-mat-autocomplete mat-form-field.mat-focused .mat-mdc-input-element, .nv-mat-dialog-common-markup mat-form-field.mat-focused .mat-mdc-input-element {
  caret-color: var(--color-blue);
}
.nv-mat-autocomplete mat-form-field.mat-form-field-invalid .mat-form-field-label, .nv-mat-dialog-common-markup mat-form-field.mat-form-field-invalid .mat-form-field-label, .nv-mat-autocomplete mat-form-field.mat-focused .mat-form-field-label, .nv-mat-dialog-common-markup mat-form-field.mat-focused .mat-form-field-label {
  color: var(--color-blue-50);
}

.nv-dialog .mat-mdc-input-element, .nv-mat-dialog-common-markup mat-form-field .mat-mdc-input-element {
  background-color: var(--color-blue-03);
  border: var(--space-xxxs) solid var(--color-blue-10);
  border-radius: var(--space-xs);
  color: var(--color-blue);
  font-size: var(--font-s);
  margin: var(--space-xs) 0px;
  outline: 0;
  overflow: hidden;
  padding: var(--space-s) var(--space-m);
  position: relative;
}
.nv-dialog .mat-mdc-input-element::placeholder, .nv-mat-dialog-common-markup mat-form-field .mat-mdc-input-element::placeholder {
  color: var(--color-blue-50);
}

.nv-dialog .mat-mdc-input-element, .nv-mat-dialog-common-markup mat-form-field .mat-mdc-input-element {
  background-size: var(--space-l);
  background-image: url("/assets/v3/img/icon-search-30.png");
  background-repeat: no-repeat;
  background-position: var(--space-s) center;
  padding-left: var(--space-xxl);
}
.nv-dialog .mat-mdc-input-element:focus, .nv-mat-dialog-common-markup mat-form-field .mat-mdc-input-element:focus {
  background-image: none;
  box-shadow: var(--elevation-1);
  padding-left: var(--space-m);
}

.base-modal-shell {
  box-sizing: border-box;
  display: grid;
  grid-template-areas: "header" "body" "actions";
  grid-template-rows: 1fr max-content minmax(1fr max-content);
  grid-auto-flow: row;
  grid-row-gap: var(--space-m);
  color: var(--color-primary-text);
  border-radius: var(--modal-border-radius);
  background: var(--color-white);
  border-radius: var(--space-xxs);
}
.base-modal-shell p {
  margin: 0;
  padding-left: var(--space-xs);
  color: var(--color-primary-text);
}
.base-modal-shell label {
  font-size: 12px;
  letter-spacing: 0.0156em;
  line-height: 145%;
  font-family: var(--font-family-normal);
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-family-normal);
  font-weight: 400;
  text-transform: none;
  color: var(--color-grey-dark-9);
  margin-bottom: var(--space-xs);
  display: inline-block;
  width: 100%;
}
.base-modal-shell nv-form-field label, .base-modal-shell mat-checkbox label, .base-modal-shell mat-radio-button label {
  display: unset;
  width: unset;
  margin-bottom: unset;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  letter-spacing: inherit;
  opacity: unset;
}
.base-modal-shell .dialog-body label {
  color: var(--color-primary-text);
}
.base-modal-shell .dialog-header .dialog-title {
  font-size: 20px;
  letter-spacing: 0.024em;
  line-height: 145%;
  font-family: var(--font-family-normal);
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-family-normal);
  font-weight: 700;
  text-transform: none;
  color: var(--color-grey-dark-9);
}
.base-modal-shell .dialog-header .close-button-right {
  display: flex;
  justify-content: space-between;
}
.base-modal-shell .dialog-header .arrow-button-left {
  display: flex;
  align-items: center;
}
.base-modal-shell .dialog-header .dialog-subtitle {
  font-size: 12px;
  letter-spacing: 0.0156em;
  line-height: 145%;
  font-family: var(--font-family-normal);
  font-style: normal;
  font-weight: 400;
  font-family: "NvLatoSemibold";
  font-weight: 600;
  text-transform: none;
  color: var(--color-grey-dark-9);
  opacity: var(--color-opacity-50);
}
.base-modal-shell .dialog-actions {
  grid-area: actions;
  grid-auto-flow: column;
  align-self: flex-end;
  text-align: right;
  justify-content: flex-end;
  grid-column-gap: var(--space-s);
  padding: 0;
  margin-bottom: -24px;
}
.base-modal-shell .mat-mdc-dialog-content {
  padding: 0;
}

/*
  component: mat-dialog

  `
    this.dialog.open(CustomModalComponent, {
      panelClass: 'nv-mat-dialog'
    });
  `
*/
.nv-mat-dialog mat-dialog-container .mdc-dialog__surface {
  padding: var(--space-l);
  width: 500px;
  border-radius: var(--modal-border-radius);
}

.nv-mat-dialog-sm mat-dialog-container .mdc-dialog__surface {
  padding: var(--space-l);
  width: 250px;
  border-radius: var(--modal-border-radius);
}

.nv-mat-dialog-med mat-dialog-container .mdc-dialog__surface {
  padding: var(--space-l);
  width: 600px;
  border-radius: var(--modal-border-radius);
}

.nv-mat-dialog-med-no-padding mat-dialog-container .mdc-dialog__surface {
  padding: 0;
  width: 600px;
  border-radius: var(--modal-border-radius);
}

.nv-mat-dialog-no-padding-no-overflow-wide mat-dialog-container .mdc-dialog__surface {
  padding: 0;
  width: 800px;
  max-height: 90vh;
  min-width: 700px;
  min-height: 90vh;
  overflow: hidden;
  border-radius: var(--modal-border-radius);
}

.nv-mat-dialog-wide mat-dialog-container .mdc-dialog__surface {
  padding: var(--space-l);
  width: 800px;
  border-radius: var(--modal-border-radius);
}

.nv-mat-dialog-wide-tall mat-dialog-container .mdc-dialog__surface {
  padding: var(--space-l);
  width: 800px;
  height: 600px;
  border-radius: var(--modal-border-radius);
}

.nv-mat-dialog-extra-wide mat-dialog-container .mdc-dialog__surface {
  padding: var(--space-xxl);
  width: 1000px;
  border-radius: var(--modal-border-radius);
  max-height: 95vh;
}

.nv-mat-dialog-wide-no-padding mat-dialog-container .mdc-dialog__surface {
  padding: 0;
  width: 800px;
  max-height: 90vh;
  min-width: 700px;
  min-height: 500px;
  border-radius: var(--modal-border-radius);
}

.nv-mat-dialog-common-markup mat-dialog-container .mdc-dialog__surface {
  padding: var(--space-l);
  overflow: hidden;
  width: 500px;
  overflow-y: auto;
  max-height: 95vh;
  border-radius: var(--modal-border-radius);
}
.nv-mat-dialog-common-markup mat-dialog-content {
  width: 100%;
  overflow: visible;
}
.nv-mat-dialog-common-markup mat-form-field .mat-form-field-infix {
  border: 0;
  padding: 0;
}
.nv-mat-dialog-common-markup mat-form-field .mat-mdc-input-element {
  width: 100%;
  box-sizing: border-box;
  height: var(--space-xxl);
}
.nv-mat-dialog-common-markup mat-form-field .mat-form-field-ripple {
  background-color: transparent;
}
.nv-mat-dialog-common-markup mat-dialog-actions {
  margin-top: var(--space-m);
  padding-top: 0;
  padding-bottom: 0;
}

.nv-dialog {
  background: white;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: var(--space-m);
  grid-template-areas: "title" "message" "controls";
  grid-template-rows: minmax(var(--space-xxl), auto) 1fr auto;
  border-radius: var(--space-xxs);
  box-sizing: border-box;
  overflow: auto;
  color: var(--color-blue);
}
.nv-dialog .dialog-head {
  grid-area: title;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--space-m);
}
.nv-dialog .dialog-head > div.icon,
.nv-dialog .dialog-head > h2 {
  align-self: center;
}
.nv-dialog .dialog-message {
  grid-area: message;
  display: grid;
  grid-auto-flow: row;
  align-content: center;
  grid-row-gap: var(--space-m);
}
.nv-dialog .dialog-controls {
  display: grid;
  grid-area: controls;
  grid-auto-flow: column;
  align-self: flex-end;
  text-align: right;
  justify-content: flex-end;
  grid-column-gap: var(--space-s);
}
.nv-dialog .mat-mdc-form-field {
  width: 436px;
}
.nv-dialog .mat-mdc-form-field .mat-form-field-label {
  position: relative;
  margin-left: 43px;
  color: var(--color-blue-50);
}
.nv-dialog .mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: transparent;
}
.nv-dialog .mdc-line-ripple {
  height: 0;
  display: none;
}
.nv-dialog .mat-mdc-text-field-wrapper {
  padding-bottom: 0;
}
.nv-dialog .mat-mdc-input-element {
  width: 86%;
}

.cdk-overlay-container .nv-multi-picker-menu {
  max-height: calc(50vh - 48px);
  width: 410px;
  max-width: 410px;
}
.cdk-overlay-container .nv-multi-picker-menu .mat-mdc-menu-content {
  width: 390px;
}
.cdk-overlay-container .nv-multi-picker-menu .searchbox {
  position: absolute;
  z-index: var(--z-idx-elevated);
  top: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  width: 390px;
}
.cdk-overlay-container .nv-multi-picker-menu .searchbox nv-icon {
  top: 8px;
}
.cdk-overlay-container .nv-multi-picker-menu .option-pills {
  margin-top: 42px;
  width: 390px;
}

/* 
  Common font weight name mapping
  https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
  100 - Thin
  200 - Extra Light
  300 - Light
  400 - Normal (Regular)
  500 - Medium
  600 - Semi Bold (Demi Bold)
  700 - Bold
  800 - Extra Bold (Ultra Bold)
  900 - Black (Heavy)
  950 - Extra Black (Ultra Black)
*/
/* 
  Google fonts api is missing some Lato variants, so we have to download them manually
  We selectively and manually install these pre-downloaded variants provided by Adobe typekit:
    - 500 / medium
    - 600 / semibold

  Downloaded from: https://fonts.adobe.com/fonts/lato
*/
/* Webfont: Lato-Medium */
@font-face {
  font-family: "NvLatoMedium";
  src: url("../../assets/fontAssets/Lato/fonts/Lato-Medium.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fontAssets/Lato/fonts/Lato-Medium.eot?#iefix") format("embedded-opentype"), url("../../assets/fontAssets/Lato/fonts/Lato-Medium.woff2") format("woff2"), url("../../assets/fontAssets/Lato/fonts/Lato-Medium.woff") format("woff"), url("../../assets/fontAssets/Lato/fonts/Lato-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-MediumItalic */
@font-face {
  font-family: "NvLatoMediumItalic";
  src: url("../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.woff2") format("woff2"), url("../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.woff") format("woff"), url("../../assets/fontAssets/Lato/fonts/Lato-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Semibold */
@font-face {
  font-family: "NvLatoSemibold";
  src: url("../../assets/fontAssets/Lato/fonts/Lato-Semibold.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fontAssets/Lato/fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"), url("../../assets/fontAssets/Lato/fonts/Lato-Semibold.woff2") format("woff2"), url("../../assets/fontAssets/Lato/fonts/Lato-Semibold.woff") format("woff"), url("../../assets/fontAssets/Lato/fonts/Lato-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-SemiboldItalic */
@font-face {
  font-family: "NvLatoSemiboldItalic";
  src: url("../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.eot"); /* IE9 Compat Modes */
  src: url("../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.woff2") format("woff2"), url("../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.woff") format("woff"), url("../../assets/fontAssets/Lato/fonts/Lato-SemiboldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
:root {
  --font-xxxs: 10px;
  --font-xxs: 12px;
  --font-xs: 14px;
  --font-s: 16px;
  --font-m: 18px;
  --font-l: 20px;
  --font-xl: 22px;
  --font-xxl: 24px;
  --font-xxxl: 32px;
  --font-4xl: 40px;
  --font-family-normal: "Lato", --apple-system, sans-serif;
  --font-family-mono: "Lato", Courrier, monospace;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-weight-bolder: 900;
}

:root {
  --space-xxxs: 1px;
  --space-xxs: 2px;
  --space-xs: 4px;
  --space-s: 8px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 40px;
  --space-xxxl: 48px;
  --space-4xl: 56px;
  --space-5xl: 64px;
  --space-6xl: 72px;
  --space-7xl: 80px;
  --elevation-1: 0px 0.6px 1.8px rgba(0, 0, 0, 0.25);
  --elevation-2: 0px 2px 5px rgba(0, 0, 0, 0.25);
  --elevation-3: 0px 4px 10px rgba(0, 0, 0, 0.25);
  --elevation-4: 0px 11px 15px -7px rgba(0, 0, 0, 0.25), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --elevation-sticky-header: 0 4px 2px -2px rgba(0, 0, 0, 0.25);
  --elevation-history-log-side-nav: inset 4px 0px 4px -4px rgba(0, 0, 0, 0.3);
  --scroll-bar: 9px;
  --scroll-bar-color: var(--color-blue-light-7);
  --sidebar-min-width: 208px;
  --content-min-width: 864px;
  --right-nav-header: var(--sidebar-header-height);
  --sidebar-item-height: var(--space-xxl);
  --sidebar-item-width: 184px;
  --sidebar-selected-color: var(--color-blue-light-5);
  --responsive-navbar-on-the-side-item-width: 128px;
  --responsive-navbar-on-the-side-item-height: 88px;
  --responsive-navbar-on-the-top-item-height: 64px;
  --responsive-navbar-full-screen-item-height: 76px;
  --z-idx-neg: -1;
  --z-idx-0: 0;
  --z-idx-1: 1;
  --z-idx-standard: 2;
  --z-idx-header: 3;
  --z-idx-shadow: 4;
  --z-idx-elevated: 5;
  --z-idx-tooltip: 6;
  --z-idx-nav: 10;
  --z-idx-env: 100;
  --app-min-width: 1280px;
  --app-max-width: 1600px;
  --modal-border-radius: 12px;
  --button-border-radius: 8px;
  --table-row-border: 1px solid var(--color-grey-light-7);
  --table-total-row-border: 1px solid var(--color-grey-light-9);
}

:root {
  --color-white: #ffffff;
  --color-white-90: white;
  --color-white-80: white;
  --color-white-70: white;
  --color-white-60: white;
  --color-white-50: white;
  --color-white-40: white;
  --color-white-30: white;
  --color-white-20: white;
  --color-white-10: white;
  --color-white-5: white;
  --color-white-3: white;
  --color-white-1: white;
  --color-white-dark: #e6e6e6;
  --color-white-dark-80: #cccccc;
  --color-white-dark-70: #b3b3b3;
  --color-white-dark-60: #999999;
  --color-white-dark-50: gray;
  --color-black: #000000;
  --color-black-90: #1a1a1a;
  --color-black-80: #333333;
  --color-black-70: #4d4d4d;
  --color-black-60: #666666;
  --color-black-50: gray;
  --color-black-40: #999999;
  --color-black-30: #b3b3b3;
  --color-black-20: #cccccc;
  --color-black-10: #e6e6e6;
  --color-black-5: #f2f2f2;
  --color-black-3: #f7f7f7;
  --color-black-1: #fcfcfc;
  --color-black-dark: black;
  --color-black-dark-80: black;
  --color-black-dark-70: black;
  --color-black-dark-60: black;
  --color-black-dark-50: black;
  --color-red: rgb(194, 0, 24);
  --color-red-90: #c81a2f;
  --color-red-80: #ce3346;
  --color-red-70: #d44d5d;
  --color-red-60: #da6674;
  --color-red-50: #e1808c;
  --color-red-40: #e799a3;
  --color-red-30: #edb3ba;
  --color-red-20: #f3ccd1;
  --color-red-10: #f9e6e8;
  --color-red-5: #fcf2f3;
  --color-red-3: #fdf7f8;
  --color-red-1: #fefcfd;
  --color-red-dark: #af0016;
  --color-red-dark-80: #9b0013;
  --color-red-dark-70: #880011;
  --color-red-dark-60: #74000e;
  --color-red-dark-50: #61000c;
  --color-pink: rgb(255, 92, 143);
  --color-pink-90: #ff6c9a;
  --color-pink-80: #ff7da5;
  --color-pink-70: #ff8db1;
  --color-pink-60: #ff9dbc;
  --color-pink-50: #ffaec7;
  --color-pink-40: #ffbed2;
  --color-pink-30: #ffcedd;
  --color-pink-20: #ffdee9;
  --color-pink-10: #ffeff4;
  --color-pink-5: #fff7f9;
  --color-pink-3: #fffafc;
  --color-pink-1: #fffdfe;
  --color-pink-dark: #e65381;
  --color-pink-dark-80: #cc4a72;
  --color-pink-dark-70: #b34064;
  --color-pink-dark-60: #993756;
  --color-pink-dark-50: #802e48;
  --color-salmon: rgb(255, 137, 137);
  --color-salmon-90: #ff9595;
  --color-salmon-80: #ffa1a1;
  --color-salmon-70: #ffacac;
  --color-salmon-60: #ffb8b8;
  --color-salmon-50: #ffc4c4;
  --color-salmon-40: #ffd0d0;
  --color-salmon-30: #ffdcdc;
  --color-salmon-20: #ffe7e7;
  --color-salmon-10: #fff3f3;
  --color-salmon-5: #fff9f9;
  --color-salmon-3: #fffbfb;
  --color-salmon-1: #fffefe;
  --color-salmon-dark: #e67b7b;
  --color-salmon-dark-80: #cc6e6e;
  --color-salmon-dark-70: #b36060;
  --color-salmon-dark-60: #995252;
  --color-salmon-dark-50: #804545;
  --color-yellow: rgb(255, 196, 0);
  --color-yellow-90: #ffca1a;
  --color-yellow-80: #ffd033;
  --color-yellow-70: #ffd64d;
  --color-yellow-60: #ffdc66;
  --color-yellow-50: #ffe280;
  --color-yellow-40: #ffe799;
  --color-yellow-30: #ffedb3;
  --color-yellow-20: #fff3cc;
  --color-yellow-10: #fff9e6;
  --color-yellow-5: #fffcf2;
  --color-yellow-3: #fffdf7;
  --color-yellow-1: #fffefc;
  --color-yellow-dark: #e6b000;
  --color-yellow-dark-80: #cc9d00;
  --color-yellow-dark-70: #b38900;
  --color-yellow-dark-60: #997600;
  --color-yellow-dark-50: #806200;
  --color-green: rgb(0, 155, 72);
  --color-green-90: #1aa55a;
  --color-green-80: #33af6d;
  --color-green-70: #4db97f;
  --color-green-60: #66c391;
  --color-green-50: #80cda4;
  --color-green-40: #99d7b6;
  --color-green-30: #b3e1c8;
  --color-green-20: #ccebda;
  --color-green-10: #e6f5ed;
  --color-green-5: #f2faf6;
  --color-green-3: #f7fcfa;
  --color-green-1: #fcfefd;
  --color-green-dark: #008c41;
  --color-green-dark-80: #007c3a;
  --color-green-dark-70: #006d32;
  --color-green-dark-60: #005d2b;
  --color-green-dark-50: #004e24;
  --color-teal: rgb(10, 196, 172);
  --color-teal-90: #23cab4;
  --color-teal-80: #3bd0bd;
  --color-teal-70: #54d6c5;
  --color-teal-60: #6cdccd;
  --color-teal-50: #85e2d6;
  --color-teal-40: #9de7de;
  --color-teal-30: #b6ede6;
  --color-teal-20: #cef3ee;
  --color-teal-10: #e7f9f7;
  --color-teal-5: #f3fcfb;
  --color-teal-3: #f8fdfd;
  --color-teal-1: #fdfefe;
  --color-teal-dark: #09b09b;
  --color-teal-dark-80: #089d8a;
  --color-teal-dark-70: #078978;
  --color-teal-dark-60: #067667;
  --color-teal-dark-50: #056256;
  --color-blue: rgb(10, 74, 206);
  --color-blue-90: #235cd3;
  --color-blue-80: #3b6ed8;
  --color-blue-70: #5480dd;
  --color-blue-60: #6c92e2;
  --color-blue-50: #85a5e7;
  --color-blue-40: #9db7eb;
  --color-blue-30: #b6c9f0;
  --color-blue-20: #cedbf5;
  --color-blue-10: #e7edfa;
  --color-blue-5: #f3f6fd;
  --color-blue-3: #f8fafe;
  --color-blue-1: #fdfdff;
  --color-blue-dark: #0943b9;
  --color-blue-dark-80: #083ba5;
  --color-blue-dark-70: #073490;
  --color-blue-dark-60: #062c7c;
  --color-blue-dark-50: #052567;
  --color-navy: rgb(0, 1, 101);
  --color-navy-90: #1a1a74;
  --color-navy-80: #333484;
  --color-navy-70: #4d4d93;
  --color-navy-60: #6667a3;
  --color-navy-50: #8080b2;
  --color-navy-40: #9999c1;
  --color-navy-30: #b3b3d1;
  --color-navy-20: #cccce0;
  --color-navy-10: #e6e6f0;
  --color-navy-5: #f2f2f7;
  --color-navy-3: #f7f7fa;
  --color-navy-1: #fcfcfd;
  --color-navy-dark: #00015b;
  --color-navy-dark-80: #000151;
  --color-navy-dark-70: #000147;
  --color-navy-dark-60: #00013d;
  --color-navy-dark-50: #000133;
  --color-purple: rgb(102, 45, 145);
  --color-purple-90: #75429c;
  --color-purple-80: #8557a7;
  --color-purple-70: #946cb2;
  --color-purple-60: #a381bd;
  --color-purple-50: #b396c8;
  --color-purple-40: #c2abd3;
  --color-purple-30: #d1c0de;
  --color-purple-20: #e0d5e9;
  --color-purple-10: #f0eaf4;
  --color-purple-5: #f7f5fa;
  --color-purple-3: #faf9fc;
  --color-purple-1: #fdfdfe;
  --color-purple-dark: #5c2983;
  --color-purple-dark-80: #522474;
  --color-purple-dark-70: #472066;
  --color-purple-dark-60: #3d1b57;
  --color-purple-dark-50: #331749;
  --color-blue-05: var(--color-blue-5);
  --color-blue-03: var(--color-blue-3);
  --color-blue-01: var(--color-blue-1);
  --color-red-pill: --var(--color-pink);
  --color-white: #FFFFFF;
  --color-grey-dark-9: #09090B;
  --color-grey-dark-8: #18181B;
  --color-grey-dark-7: #27272A;
  --color-grey-dark-6: #3F3F46;
  --color-grey-dark-5: #52525B;
  --color-grey: #71717A;
  --color-grey-light-9: #A1A1AA;
  --color-grey-light-8: #D4D4D8;
  --color-grey-light-7: #E4E4E7;
  --color-grey-light-6: #F4F4F5;
  --color-grey-light-5: #FAFAFA;
  --color-blue-dark-9: #1D2758;
  --color-blue-dark-8: #2A3884;
  --color-blue-dark-7: #2E41A8;
  --color-blue-dark-6: #3850D2;
  --color-blue-dark-5: #3754EB;
  --color-blue: #4765FF;
  --color-blue-light-9: #7A8FFF;
  --color-blue-light-8: #A3B2FF;
  --color-blue-light-7: #BDC7FF;
  --color-blue-light-6: #E0E5FF;
  --color-blue-light-5: #F5F6FF;
  --color-red-dark-2: #991B1B;
  --color-red-dark-1: #B91C1C;
  --color-red: #DC2626;
  --color-red-light-1: #FECACA;
  --color-red-light-2: #F6EDED;
  --color-green-dark-2: #166534;
  --color-green-dark-1: #15803D;
  --color-green: #16A34A;
  --color-green-light-1: #BBF7D0;
  --color-green-light-2: #ECF5EF;
  --color-yellow-dark-2: #775C08;
  --color-yellow-dark-1: #C49608;
  --color-yellow: #F2C436;
  --color-yellow-light-1: #F4E1A4;
  --color-yellow-light-2: #FDF9ED;
  --color-purple-dark-1: #8A5DB6;
  --color-purple-light-1: #F4EFF8;
  --color-primary-text: var(--color-grey-dark-8);
  --color-primary-text-disabled: var(--color-grey-light-9);
  --color-standard-bar-blue: var(--color-blue-light-9);
  --color-blue-text: var(--color-blue);
  --color-blue-text-with-non-white-background: var(--color-blue-dark-5);
  --color-app-default-bg: var(--color-grey-light-5);
  --color-standard-bar-blue: #7A8FFF;
  --color-stacked-bar-seq-positive-5: #00494B;
  --color-stacked-bar-seq-positive-4: #076076;
  --color-stacked-bar-seq-positive-3: #076769;
  --color-stacked-bar-seq-positive-2: #3EA8A6;
  --color-stacked-bar-seq-positive-1: #BBE4D1;
  --color-stacked-bar-seq-neutral: #D4D4D8;
  --color-stacked-bar-seq-negative-1: #F0A882;
  --color-stacked-bar-seq-negative-2: #E47961;
  --color-stacked-bar-seq-negative-3: #C65154;
  --color-stacked-bar-seq-negative-4: #A52747;
  --color-stacked-bar-seq-negative-5: #81102D;
  --color-stacked-bar-cat-green: #2FB796;
  --color-stacked-bar-cat-blue: #516AEC;
  --color-stacked-bar-cat-yellow: #E7AD55;
  --color-stacked-bar-cat-purple: #8A5D86;
  --color-opacity-none: 0;
  --color-opacity-10: 0.1;
  --color-opacity-30: 0.3;
  --color-opacity-50: 0.5;
}

:root {
  --app-background-image: url(https://nv-portal-landing-1.firebaseapp.com/assets/new-login-bg.svg);
}

/**
  * Global Typography mixin utilities
  * contains mixin utilities for typography
  * 
*/
/**
  * Global Typography mixin
  * mixin can be provided with two optional args
  * 
  * arg `$color` - color of the text, use color variable, default is grey-dark-9
  * arg `$fontStyle` - font style of the text, either normal or italic, default is normal
  *
  * Updates the following properties:
  * - font-size, letter-spacing, line-height
  * - font-family, font-style, font-weight
  * - text-transform
  * - font-color
  * 
  * Usage example:
  * .a-button-class {
  *   @include nv-font-button('blue', italic);
  * }
  * 
  * .a-caption-class {
  *   @include nv-font-caption-1('grey-dark-9');
  * }
  * 
  * .a-body-class {
  *   @include nv-font-body-2(null, italic);
  * }
  * 
  * .a-title-class {
  *   @include nv-font-header-1();
  * }
  * 
*/
/**
  * Global Typography mixin utilities
  * contains mixin utilities for typography
  * 
*/
/**
  * Global Typography mixin
  * mixin can be provided with two optional args
  * 
  * arg `$color` - color of the text, use color variable, default is grey-dark-9
  * arg `$fontStyle` - font style of the text, either normal or italic, default is normal
  *
  * Updates the following properties:
  * - font-size, letter-spacing, line-height
  * - font-family, font-style, font-weight
  * - text-transform
  * - font-color
  * 
  * Usage example:
  * .a-button-class {
  *   @include nv-font-button('blue', italic);
  * }
  * 
  * .a-caption-class {
  *   @include nv-font-caption-1('grey-dark-9');
  * }
  * 
  * .a-body-class {
  *   @include nv-font-body-2(null, italic);
  * }
  * 
  * .a-title-class {
  *   @include nv-font-header-1();
  * }
  * 
*/
:root {
  --color-white: #ffffff;
  --color-white-90: white;
  --color-white-80: white;
  --color-white-70: white;
  --color-white-60: white;
  --color-white-50: white;
  --color-white-40: white;
  --color-white-30: white;
  --color-white-20: white;
  --color-white-10: white;
  --color-white-5: white;
  --color-white-3: white;
  --color-white-1: white;
  --color-white-dark: #e6e6e6;
  --color-white-dark-80: #cccccc;
  --color-white-dark-70: #b3b3b3;
  --color-white-dark-60: #999999;
  --color-white-dark-50: gray;
  --color-black: #000000;
  --color-black-90: #1a1a1a;
  --color-black-80: #333333;
  --color-black-70: #4d4d4d;
  --color-black-60: #666666;
  --color-black-50: gray;
  --color-black-40: #999999;
  --color-black-30: #b3b3b3;
  --color-black-20: #cccccc;
  --color-black-10: #e6e6e6;
  --color-black-5: #f2f2f2;
  --color-black-3: #f7f7f7;
  --color-black-1: #fcfcfc;
  --color-black-dark: black;
  --color-black-dark-80: black;
  --color-black-dark-70: black;
  --color-black-dark-60: black;
  --color-black-dark-50: black;
  --color-red: rgb(194, 0, 24);
  --color-red-90: #c81a2f;
  --color-red-80: #ce3346;
  --color-red-70: #d44d5d;
  --color-red-60: #da6674;
  --color-red-50: #e1808c;
  --color-red-40: #e799a3;
  --color-red-30: #edb3ba;
  --color-red-20: #f3ccd1;
  --color-red-10: #f9e6e8;
  --color-red-5: #fcf2f3;
  --color-red-3: #fdf7f8;
  --color-red-1: #fefcfd;
  --color-red-dark: #af0016;
  --color-red-dark-80: #9b0013;
  --color-red-dark-70: #880011;
  --color-red-dark-60: #74000e;
  --color-red-dark-50: #61000c;
  --color-pink: rgb(255, 92, 143);
  --color-pink-90: #ff6c9a;
  --color-pink-80: #ff7da5;
  --color-pink-70: #ff8db1;
  --color-pink-60: #ff9dbc;
  --color-pink-50: #ffaec7;
  --color-pink-40: #ffbed2;
  --color-pink-30: #ffcedd;
  --color-pink-20: #ffdee9;
  --color-pink-10: #ffeff4;
  --color-pink-5: #fff7f9;
  --color-pink-3: #fffafc;
  --color-pink-1: #fffdfe;
  --color-pink-dark: #e65381;
  --color-pink-dark-80: #cc4a72;
  --color-pink-dark-70: #b34064;
  --color-pink-dark-60: #993756;
  --color-pink-dark-50: #802e48;
  --color-salmon: rgb(255, 137, 137);
  --color-salmon-90: #ff9595;
  --color-salmon-80: #ffa1a1;
  --color-salmon-70: #ffacac;
  --color-salmon-60: #ffb8b8;
  --color-salmon-50: #ffc4c4;
  --color-salmon-40: #ffd0d0;
  --color-salmon-30: #ffdcdc;
  --color-salmon-20: #ffe7e7;
  --color-salmon-10: #fff3f3;
  --color-salmon-5: #fff9f9;
  --color-salmon-3: #fffbfb;
  --color-salmon-1: #fffefe;
  --color-salmon-dark: #e67b7b;
  --color-salmon-dark-80: #cc6e6e;
  --color-salmon-dark-70: #b36060;
  --color-salmon-dark-60: #995252;
  --color-salmon-dark-50: #804545;
  --color-yellow: rgb(255, 196, 0);
  --color-yellow-90: #ffca1a;
  --color-yellow-80: #ffd033;
  --color-yellow-70: #ffd64d;
  --color-yellow-60: #ffdc66;
  --color-yellow-50: #ffe280;
  --color-yellow-40: #ffe799;
  --color-yellow-30: #ffedb3;
  --color-yellow-20: #fff3cc;
  --color-yellow-10: #fff9e6;
  --color-yellow-5: #fffcf2;
  --color-yellow-3: #fffdf7;
  --color-yellow-1: #fffefc;
  --color-yellow-dark: #e6b000;
  --color-yellow-dark-80: #cc9d00;
  --color-yellow-dark-70: #b38900;
  --color-yellow-dark-60: #997600;
  --color-yellow-dark-50: #806200;
  --color-green: rgb(0, 155, 72);
  --color-green-90: #1aa55a;
  --color-green-80: #33af6d;
  --color-green-70: #4db97f;
  --color-green-60: #66c391;
  --color-green-50: #80cda4;
  --color-green-40: #99d7b6;
  --color-green-30: #b3e1c8;
  --color-green-20: #ccebda;
  --color-green-10: #e6f5ed;
  --color-green-5: #f2faf6;
  --color-green-3: #f7fcfa;
  --color-green-1: #fcfefd;
  --color-green-dark: #008c41;
  --color-green-dark-80: #007c3a;
  --color-green-dark-70: #006d32;
  --color-green-dark-60: #005d2b;
  --color-green-dark-50: #004e24;
  --color-teal: rgb(10, 196, 172);
  --color-teal-90: #23cab4;
  --color-teal-80: #3bd0bd;
  --color-teal-70: #54d6c5;
  --color-teal-60: #6cdccd;
  --color-teal-50: #85e2d6;
  --color-teal-40: #9de7de;
  --color-teal-30: #b6ede6;
  --color-teal-20: #cef3ee;
  --color-teal-10: #e7f9f7;
  --color-teal-5: #f3fcfb;
  --color-teal-3: #f8fdfd;
  --color-teal-1: #fdfefe;
  --color-teal-dark: #09b09b;
  --color-teal-dark-80: #089d8a;
  --color-teal-dark-70: #078978;
  --color-teal-dark-60: #067667;
  --color-teal-dark-50: #056256;
  --color-blue: rgb(10, 74, 206);
  --color-blue-90: #235cd3;
  --color-blue-80: #3b6ed8;
  --color-blue-70: #5480dd;
  --color-blue-60: #6c92e2;
  --color-blue-50: #85a5e7;
  --color-blue-40: #9db7eb;
  --color-blue-30: #b6c9f0;
  --color-blue-20: #cedbf5;
  --color-blue-10: #e7edfa;
  --color-blue-5: #f3f6fd;
  --color-blue-3: #f8fafe;
  --color-blue-1: #fdfdff;
  --color-blue-dark: #0943b9;
  --color-blue-dark-80: #083ba5;
  --color-blue-dark-70: #073490;
  --color-blue-dark-60: #062c7c;
  --color-blue-dark-50: #052567;
  --color-navy: rgb(0, 1, 101);
  --color-navy-90: #1a1a74;
  --color-navy-80: #333484;
  --color-navy-70: #4d4d93;
  --color-navy-60: #6667a3;
  --color-navy-50: #8080b2;
  --color-navy-40: #9999c1;
  --color-navy-30: #b3b3d1;
  --color-navy-20: #cccce0;
  --color-navy-10: #e6e6f0;
  --color-navy-5: #f2f2f7;
  --color-navy-3: #f7f7fa;
  --color-navy-1: #fcfcfd;
  --color-navy-dark: #00015b;
  --color-navy-dark-80: #000151;
  --color-navy-dark-70: #000147;
  --color-navy-dark-60: #00013d;
  --color-navy-dark-50: #000133;
  --color-purple: rgb(102, 45, 145);
  --color-purple-90: #75429c;
  --color-purple-80: #8557a7;
  --color-purple-70: #946cb2;
  --color-purple-60: #a381bd;
  --color-purple-50: #b396c8;
  --color-purple-40: #c2abd3;
  --color-purple-30: #d1c0de;
  --color-purple-20: #e0d5e9;
  --color-purple-10: #f0eaf4;
  --color-purple-5: #f7f5fa;
  --color-purple-3: #faf9fc;
  --color-purple-1: #fdfdfe;
  --color-purple-dark: #5c2983;
  --color-purple-dark-80: #522474;
  --color-purple-dark-70: #472066;
  --color-purple-dark-60: #3d1b57;
  --color-purple-dark-50: #331749;
  --color-blue-05: var(--color-blue-5);
  --color-blue-03: var(--color-blue-3);
  --color-blue-01: var(--color-blue-1);
  --color-red-pill: --var(--color-pink);
  --color-white: #FFFFFF;
  --color-grey-dark-9: #09090B;
  --color-grey-dark-8: #18181B;
  --color-grey-dark-7: #27272A;
  --color-grey-dark-6: #3F3F46;
  --color-grey-dark-5: #52525B;
  --color-grey: #71717A;
  --color-grey-light-9: #A1A1AA;
  --color-grey-light-8: #D4D4D8;
  --color-grey-light-7: #E4E4E7;
  --color-grey-light-6: #F4F4F5;
  --color-grey-light-5: #FAFAFA;
  --color-blue-dark-9: #1D2758;
  --color-blue-dark-8: #2A3884;
  --color-blue-dark-7: #2E41A8;
  --color-blue-dark-6: #3850D2;
  --color-blue-dark-5: #3754EB;
  --color-blue: #4765FF;
  --color-blue-light-9: #7A8FFF;
  --color-blue-light-8: #A3B2FF;
  --color-blue-light-7: #BDC7FF;
  --color-blue-light-6: #E0E5FF;
  --color-blue-light-5: #F5F6FF;
  --color-red-dark-2: #991B1B;
  --color-red-dark-1: #B91C1C;
  --color-red: #DC2626;
  --color-red-light-1: #FECACA;
  --color-red-light-2: #F6EDED;
  --color-green-dark-2: #166534;
  --color-green-dark-1: #15803D;
  --color-green: #16A34A;
  --color-green-light-1: #BBF7D0;
  --color-green-light-2: #ECF5EF;
  --color-yellow-dark-2: #775C08;
  --color-yellow-dark-1: #C49608;
  --color-yellow: #F2C436;
  --color-yellow-light-1: #F4E1A4;
  --color-yellow-light-2: #FDF9ED;
  --color-purple-dark-1: #8A5DB6;
  --color-purple-light-1: #F4EFF8;
  --color-primary-text: var(--color-grey-dark-8);
  --color-primary-text-disabled: var(--color-grey-light-9);
  --color-standard-bar-blue: var(--color-blue-light-9);
  --color-blue-text: var(--color-blue);
  --color-blue-text-with-non-white-background: var(--color-blue-dark-5);
  --color-app-default-bg: var(--color-grey-light-5);
  --color-standard-bar-blue: #7A8FFF;
  --color-stacked-bar-seq-positive-5: #00494B;
  --color-stacked-bar-seq-positive-4: #076076;
  --color-stacked-bar-seq-positive-3: #076769;
  --color-stacked-bar-seq-positive-2: #3EA8A6;
  --color-stacked-bar-seq-positive-1: #BBE4D1;
  --color-stacked-bar-seq-neutral: #D4D4D8;
  --color-stacked-bar-seq-negative-1: #F0A882;
  --color-stacked-bar-seq-negative-2: #E47961;
  --color-stacked-bar-seq-negative-3: #C65154;
  --color-stacked-bar-seq-negative-4: #A52747;
  --color-stacked-bar-seq-negative-5: #81102D;
  --color-stacked-bar-cat-green: #2FB796;
  --color-stacked-bar-cat-blue: #516AEC;
  --color-stacked-bar-cat-yellow: #E7AD55;
  --color-stacked-bar-cat-purple: #8A5D86;
  --color-opacity-none: 0;
  --color-opacity-10: 0.1;
  --color-opacity-30: 0.3;
  --color-opacity-50: 0.5;
}

/*
  component: mat-autocomplete

  `<div class="nv-mat-autocomplete">
    <form>
      <mat-form-field>
        <input>
        <mat-autocomplete >
          <mat-option>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>`
*/
.mat-mdc-autocomplete-panel-above .nv-mat-autocomplete.mdc-menu-surface--open {
  margin-bottom: var(--space-s);
}

.nv-mat-autocomplete.mat-mdc-autocomplete-panel.mdc-menu-surface--open {
  padding: 0;
  max-height: 256px;
  margin-top: var(--space-s);
  overflow-x: hidden;
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option {
  padding: 0 var(--space-m);
  cursor: pointer;
  line-height: 1em;
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option:hover {
  background: var(--color-blue-light-5);
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option.mat-active {
  background: var(--color-blue-05);
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option.mat-mdc-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: var(--color-blue);
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mdc-list-item__primary-text,
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mat-mdc-menu-item-text, .nv-mat-autocomplete .mat-mdc-option.nv-mat-option.mat-active .mdc-list-item__primary-text {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: var(--space-xxs);
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mdc-list-item__primary-text .nv-mat-option-label,
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mat-mdc-menu-item-text .nv-mat-option-label, .nv-mat-autocomplete .mat-mdc-option.nv-mat-option.mat-active .mdc-list-item__primary-text .nv-mat-option-label {
  font-size: var(--font-xs);
  font-weight: var(--font-weight-normal);
  color: var(--color-grey-dark-8);
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mdc-list-item__primary-text .nv-mat-option-label em,
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mat-mdc-menu-item-text .nv-mat-option-label em, .nv-mat-autocomplete .mat-mdc-option.nv-mat-option.mat-active .mdc-list-item__primary-text .nv-mat-option-label em {
  background-color: var(--color-yellow-50);
  color: var(--color-navy);
  font-style: normal;
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mdc-list-item__primary-text .nv-mat-option-tags,
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mat-mdc-menu-item-text .nv-mat-option-tags, .nv-mat-autocomplete .mat-mdc-option.nv-mat-option.mat-active .mdc-list-item__primary-text .nv-mat-option-tags {
  font-size: var(--font-xxs);
  opacity: var(--color-opacity-50);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  text-overflow: ellipsis;
  overflow-x: hidden;
  color: var(--color-grey-dark-8);
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mdc-list-item__primary-text .nv-mat-option-tags em,
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mat-mdc-menu-item-text .nv-mat-option-tags em, .nv-mat-autocomplete .mat-mdc-option.nv-mat-option.mat-active .mdc-list-item__primary-text .nv-mat-option-tags em {
  background-color: var(--color-yellow-50);
  color: var(--color-navy);
  font-style: normal;
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option.nv-mat-option-empty_state {
  font-style: italic;
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option.nv-mat-option-empty_state span {
  color: var(--color-primary-text);
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option.nv-mat-option-empty_state:hover, .nv-mat-autocomplete .mat-mdc-option.nv-mat-option.nv-mat-option-empty_state *:hover {
  background: transparent;
  cursor: default;
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option.nv-mat-option-empty_state_action .mdc-list-item__primary-text {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: var(--space-xl);
  align-items: center;
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option.nv-mat-option-empty_state_action .mdc-list-item__primary-text span {
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--color-primary-text);
}
.nv-mat-autocomplete .mat-mdc-option.nv-mat-option .mat-mdc-option-ripple.mat-ripple * {
  opacity: 0.1;
  background: var(--color-navy);
}
.nv-mat-autocomplete mat-form-field .mdc-text-field--filled {
  padding: 0;
}
.nv-mat-autocomplete mat-form-field .mdc-text-field--filled .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding-bottom: var(--space-s);
}
.nv-mat-autocomplete mat-form-field .mat-mdc-form-field-subscript-wrapper {
  height: 0;
}
.nv-mat-autocomplete mat-form-field .mat-form-field-infix {
  border: 0;
}
.nv-mat-autocomplete mat-form-field.mat-form-field-invalid .mat-mdc-input-element, .nv-mat-autocomplete mat-form-field.mat-focused .mat-mdc-input-element {
  caret-color: black;
}
.nv-mat-autocomplete mat-form-field.mat-form-field-invalid .mat-form-field-label, .nv-mat-autocomplete mat-form-field.mat-focused .mat-form-field-label {
  color: var(--color-blue-50);
}

/**
  * Global styles for autocomplete panel
  * These styles reset padding of the autocomplete panel to what the default was prior to angular material v15
*/
.mat-mdc-autocomplete-panel.mdc-menu-surface.mdc-menu-surface--open {
  padding: 0;
}

/**
  * Global Typography mixin utilities
  * contains mixin utilities for typography
  * 
*/
/**
  * Global Typography mixin
  * mixin can be provided with two optional args
  * 
  * arg `$color` - color of the text, use color variable, default is grey-dark-9
  * arg `$fontStyle` - font style of the text, either normal or italic, default is normal
  *
  * Updates the following properties:
  * - font-size, letter-spacing, line-height
  * - font-family, font-style, font-weight
  * - text-transform
  * - font-color
  * 
  * Usage example:
  * .a-button-class {
  *   @include nv-font-button('blue', italic);
  * }
  * 
  * .a-caption-class {
  *   @include nv-font-caption-1('grey-dark-9');
  * }
  * 
  * .a-body-class {
  *   @include nv-font-body-2(null, italic);
  * }
  * 
  * .a-title-class {
  *   @include nv-font-header-1();
  * }
  * 
*/
/*
  component: mat-calendar

  <mat-datepicker></<mat-datepicker>
*/
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar {
  height: auto;
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content {
  padding: 0;
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-table-header {
  background-color: var(--color-blue-light-5);
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-table-header th {
  padding: 4px 0;
  font-size: 10px;
  letter-spacing: 0.0144em;
  line-height: 100%;
  font-family: var(--font-family-normal);
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-family-normal);
  font-weight: 400;
  text-transform: none;
  color: var(--color-blue);
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-table-header :nth-child(1) {
  height: var(--space-xl);
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-table-header .mat-calendar-table-header-divider {
  display: none;
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body {
  color: var(--color-blue);
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body tr[aria-hidden=true] {
  display: none;
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .mat-calendar-body-label {
  opacity: 0;
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: var(--color-blue-03);
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .mat-calendar-body-cell .mat-calendar-body-cell-content {
  font-size: 14px;
  letter-spacing: 0.0168em;
  line-height: 145%;
  font-family: var(--font-family-normal);
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-family-normal);
  font-weight: 400;
  text-transform: none;
  color: var(--color-blue);
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .mat-calendar-body-disabled .mat-calendar-body-cell-content {
  color: var(--color-blue);
  opacity: var(--color-opacity-50);
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .mat-calendar-body-disabled .mat-calendar-body-cell-content:hover {
  background-color: inherit;
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .mat-calendar-body-cell .mat-calendar-body-selected {
  color: white;
  background-color: var(--color-blue);
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: var(--color-blue);
  opacity: var(--color-opacity-50);
}
mat-datepicker-content.mat-datepicker-content mat-calendar.mat-calendar .mat-calendar-content .mat-calendar-table tbody.mat-calendar-body .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: var(--color-blue);
}

/**
  * Global Typography mixin utilities
  * contains mixin utilities for typography
  * 
*/
/**
  * Global Typography mixin
  * mixin can be provided with two optional args
  * 
  * arg `$color` - color of the text, use color variable, default is grey-dark-9
  * arg `$fontStyle` - font style of the text, either normal or italic, default is normal
  *
  * Updates the following properties:
  * - font-size, letter-spacing, line-height
  * - font-family, font-style, font-weight
  * - text-transform
  * - font-color
  * 
  * Usage example:
  * .a-button-class {
  *   @include nv-font-button('blue', italic);
  * }
  * 
  * .a-caption-class {
  *   @include nv-font-caption-1('grey-dark-9');
  * }
  * 
  * .a-body-class {
  *   @include nv-font-body-2(null, italic);
  * }
  * 
  * .a-title-class {
  *   @include nv-font-header-1();
  * }
  * 
*/
/*
  component: mat-checkbox

  `<mat-checkbox disableRipple class="nv-mat-checkbox"></mat-checkbox>`
*/
/**
 *
 * currently only been used in - jchu:
 * flag-log-action-modal.component.html
 * school-tools-sync.component.html
 * cluster-user-modal-shell.component.html
 * cluster-user-portfolio.component.html
 *
 */
.nv-mat-checkbox {
  display: flex;
  margin: 0px;
  /**
    * Most of the styles here reset checkbox with class nv-mat-checkbox to what it looked prior to the angular material v15 upgrade
    *
    * Styles applied with the !important CSS rule are needed because of how high the specifity angular material CSS styles are applied with
  */
}
.nv-mat-checkbox.mat-mdc-checkbox, .nv-mat-checkbox.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: var(--color-blue);
  --mdc-checkbox-selected-hover-icon-color: var(--color-blue);
  --mdc-checkbox-selected-icon-color: var(--color-blue);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-blue);
  --mdc-checkbox-unselected-focus-icon-color: var(--color-grey-dark-8);
  --mdc-checkbox-unselected-hover-icon-color: var(--color-grey-dark-8);
  --mdc-checkbox-disabled-selected-icon-color: var(--color-blue-light-7);
  --mdc-checkbox-disabled-unselected-icon-color: var(--color-grey-light-9);
  --mdc-checkbox-unselected-icon-color: var(--color-grey-dark-8);
  --mdc-checkbox-unselected-pressed-icon-color: var(--color-grey-dark-8);
}
.nv-mat-checkbox .mdc-form-field {
  height: 16px;
  margin-left: 1px;
}
.nv-mat-checkbox .mdc-form-field .mdc-checkbox {
  cursor: default;
  margin: 0 -12px;
  padding-top: 9px;
}
.nv-mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background,
.nv-mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  width: 16px;
  height: 16px;
}
.nv-mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark,
.nv-mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target .mdc-checkbox__mixedmark {
  border-radius: 16px;
  width: 8px;
}
.nv-mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__native-control {
  top: 11px;
  left: 11px;
  width: 16px;
  height: 16px;
}
.nv-mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__native-control:not(:disabled) {
  cursor: pointer;
  z-index: var(--z-idx-1);
}
.nv-mat-checkbox .mdc-form-field label {
  font-size: 12px;
  letter-spacing: 0.0156em;
  line-height: 145%;
  font-family: var(--font-family-normal);
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-family-normal);
  font-weight: 400;
  text-transform: none;
  color: var(--color-grey-dark-9);
  z-index: var(--z-idx-1);
  padding-left: var(--space-s);
}
.nv-mat-checkbox.mat-mdc-checkbox .mdc-checkbox__native-control ~ .mdc-checkbox__ripple, .nv-mat-checkbox.mat-mdc-checkbox.mat-accent .mdc-checkbox__native-control ~ .mdc-checkbox__ripple {
  background: unset;
  opacity: 0;
  display: none;
}
.nv-mat-checkbox.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control ~ .mdc-checkbox__ripple, .nv-mat-checkbox.mat-mdc-checkbox.mat-accent .mdc-checkbox:hover .mdc-checkbox__native-control ~ .mdc-checkbox__ripple {
  opacity: 0;
}
.nv-mat-checkbox.mdc-checkbox--disabled {
  pointer-events: unset;
}
.nv-mat-checkbox.mdc-checkbox--disabled .mdc-form-field .mdc-checkbox:hover {
  cursor: not-allowed;
}
.nv-mat-checkbox.mdc-checkbox--disabled .mdc-form-field label {
  color: var(--color-primary-text-disabled);
}

/*
 * used with .nv-mat-checkbox to override default square checkbox style to a circle (AB)
 * Currently being used in:
 * fixed-table.component.html
*/
.nv-circle-mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background,
.nv-circle-mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  border-color: var(--color-blue-20) !important;
  border-radius: 70%;
  border-width: thin;
}
.nv-circle-mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(--color-blue-05);
}
.nv-circle-mat-checkbox.mat-mdc-checkbox-checked .mdc-form-field .mdc-checkbox .mdc-checkbox__background,
.nv-circle-mat-checkbox.mat-mdc-checkbox-checked .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  background-color: var(--color-blue-05) !important;
  border-color: var(--color-blue) !important;
  scale: 1.1;
}
.nv-circle-mat-checkbox.mat-mdc-checkbox-checked .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg,
.nv-circle-mat-checkbox.mat-mdc-checkbox-checked .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target svg {
  filter: brightness(0) saturate(100%) invert(23%) sepia(88%) saturate(2097%) hue-rotate(206deg) brightness(92%) contrast(96%);
  scale: 0.75;
}
.nv-circle-mat-checkbox.mat-mdc-checkbox-checked.mdc-checkbox--disabled .mdc-form-field .mdc-checkbox .mdc-checkbox__background,
.nv-circle-mat-checkbox.mat-mdc-checkbox-checked.mdc-checkbox--disabled .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  background-color: var(--color-blue-30) !important;
  border-color: var(--color-blue) !important;
}

/**
 *
 * currently been used in main page and student profile page - jchu:
 * list-display-milestone.component.html
 * list-display-career-path.component.html
 * list-display-college-path.component.html
 * list-display-student.component.html
 *
 */
.nv-checkbox-wrapper {
  padding: 0;
  justify-content: center;
  margin-left: var(--space-xs);
}
.nv-checkbox-wrapper .checkbox {
  height: var(--space-l);
  width: var(--space-l);
  display: inline-block;
  position: relative;
  border-radius: var(--space-xxs);
  background-image: url(/assets/v3/img/checkbox-off.png);
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}
.nv-checkbox-wrapper .checkbox.checkbox-selected {
  background-image: url(/assets/v3/img/checkbox-on.png);
}

mat-form-field .mdc-text-field--filled {
  background-color: transparent !important;
}
mat-form-field .mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}
mat-form-field .mat-mdc-text-field-wrapper.mdc-text-field {
  padding: 0;
}

.nv-mat-dialog-common-markup mat-form-field, .nv-mat-autocomplete mat-form-field {
  width: 100%;
}
.nv-mat-dialog-common-markup mat-form-field .mdc-line-ripple, .nv-mat-autocomplete mat-form-field .mdc-line-ripple {
  display: none;
}
.nv-mat-dialog-common-markup mat-form-field .mat-form-field-label, .nv-mat-autocomplete mat-form-field .mat-form-field-label {
  color: var(--color-blue-50);
}
.nv-mat-dialog-common-markup mat-form-field.mat-form-field-invalid .mat-mdc-input-element, .nv-mat-autocomplete mat-form-field.mat-form-field-invalid .mat-mdc-input-element, .nv-mat-dialog-common-markup mat-form-field.mat-focused .mat-mdc-input-element, .nv-mat-autocomplete mat-form-field.mat-focused .mat-mdc-input-element {
  caret-color: var(--color-blue);
}
.nv-mat-dialog-common-markup mat-form-field.mat-form-field-invalid .mat-form-field-label, .nv-mat-autocomplete mat-form-field.mat-form-field-invalid .mat-form-field-label, .nv-mat-dialog-common-markup mat-form-field.mat-focused .mat-form-field-label, .nv-mat-autocomplete mat-form-field.mat-focused .mat-form-field-label {
  color: var(--color-blue-50);
}

.cdk-overlay-pane {
  min-width: 150px;
}
.cdk-overlay-pane:not(.nv-multi-picker-panel) {
  transform: translateY(0px) !important;
}
.cdk-overlay-pane .mat-mdc-menu-content {
  padding: 0;
}
.cdk-overlay-pane .mat-mdc-menu-panel.mdc-menu-surface {
  min-height: unset;
  max-width: unset;
}
.cdk-overlay-pane .mat-mdc-menu-panel.mdc-menu-surface .mat-mdc-menu-ripple.mat-ripple .mat-ripple-element {
  background-color: var(--color-blue) !important;
  opacity: 0.05;
}
.cdk-overlay-pane .mat-mdc-select-panel.mdc-menu-surface.mat-primary.mdc-menu-surface--open {
  padding: 0;
  border-radius: 5px;
}

/*
  component: mat-option

  `<mat-option class=".nv-mat-option"></mat-option>`
*/
mat-option.nv-mat-option {
  color: var(--color-blue);
}
mat-option.nv-mat-option .mdc-list-item__primary-text {
  color: var(--color-blue);
}
mat-option.nv-mat-option.mdc-list-item.mdc-list-item--selected {
  background-color: unset;
}

/**
  * Global Typography mixin utilities
  * contains mixin utilities for typography
  * 
*/
/**
  * Global Typography mixin
  * mixin can be provided with two optional args
  * 
  * arg `$color` - color of the text, use color variable, default is grey-dark-9
  * arg `$fontStyle` - font style of the text, either normal or italic, default is normal
  *
  * Updates the following properties:
  * - font-size, letter-spacing, line-height
  * - font-family, font-style, font-weight
  * - text-transform
  * - font-color
  * 
  * Usage example:
  * .a-button-class {
  *   @include nv-font-button('blue', italic);
  * }
  * 
  * .a-caption-class {
  *   @include nv-font-caption-1('grey-dark-9');
  * }
  * 
  * .a-body-class {
  *   @include nv-font-body-2(null, italic);
  * }
  * 
  * .a-title-class {
  *   @include nv-font-header-1();
  * }
  * 
*/
/*
  component:
  `
    <mat-radio-group class="nv-mat-radio-group>
      <mat-radio-button class="nv-mat-radio-button"></mat-radio-button>
      <mat-radio-button class="nv-mat-radio-button"></mat-radio-button>
      ...
    </mat-radio-group>
  `
*/
.nv-mat-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.nv-mat-radio-group mat-radio-button,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button {
  margin-bottom: var(--space-s);
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: var(--color-blue);
  --mdc-radio-disabled-unselected-icon-color: var(--color-grey-dark-8);
  --mdc-radio-unselected-focus-icon-color: var(--color-grey-dark-8);
  --mdc-radio-unselected-hover-icon-color: var(--color-grey-dark-8);
  --mdc-radio-unselected-icon-color: var(--color-grey-dark-8);
  --mdc-radio-unselected-pressed-icon-color: var(--color-grey-dark-8);
  --mdc-radio-selected-focus-icon-color: var(--color-blue);
  --mdc-radio-selected-hover-icon-color: var(--color-blue);
  --mdc-radio-selected-icon-color: var(--color-blue);
  --mdc-radio-selected-pressed-icon-color: var(--color-blue);
  --mat-mdc-radio-checked-ripple-color: unset;
  --mat-mdc-radio-ripple-color: unset;
  --mdc-radio-disabled-unselected-icon-opacity: var(--color-opacity-30);
  --mdc-radio-disabled-selected-icon-opacity: var(--color-opacity-30);
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button .mdc-form-field label,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-form-field label {
  font-size: 12px;
  letter-spacing: 0.0156em;
  line-height: 145%;
  font-family: var(--font-family-normal);
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-family-normal);
  font-weight: 400;
  text-transform: none;
  color: var(--color-grey-dark-9);
  padding-left: 0;
  cursor: pointer;
  padding-left: var(--space-s);
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button .mdc-form-field .mdc-radio--disabled + label,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-form-field .mdc-radio--disabled + label {
  color: var(--color-primary-text-disabled);
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button .mdc-form-field .mat-mdc-radio-touch-target,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-form-field .mat-mdc-radio-touch-target {
  transform: unset;
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element {
  background-color: unset;
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button .mdc-radio,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio {
  padding: unset;
  width: var(--space-m);
  height: var(--space-m);
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background {
  width: var(--space-m);
  height: var(--space-m);
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background .mdc-radio__inner-circle,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background .mdc-radio__inner-circle {
  border-width: var(--space-s);
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  opacity: 0;
}
.nv-mat-radio-group mat-radio-button.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus) ~ .mdc-radio__background::before,
.nv-mat-radio-group mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus) ~ .mdc-radio__background::before {
  opacity: 0;
}

mat-radio-button.mat-mdc-radio-button, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: var(--color-blue);
  --mdc-radio-disabled-unselected-icon-color: var(--color-grey-dark-8);
  --mdc-radio-unselected-focus-icon-color: var(--color-grey-dark-8);
  --mdc-radio-unselected-hover-icon-color: var(--color-grey-dark-8);
  --mdc-radio-unselected-icon-color: var(--color-grey-dark-8);
  --mdc-radio-unselected-pressed-icon-color: var(--color-grey-dark-8);
  --mdc-radio-selected-focus-icon-color: var(--color-blue);
  --mdc-radio-selected-hover-icon-color: var(--color-blue);
  --mdc-radio-selected-icon-color: var(--color-blue);
  --mdc-radio-selected-pressed-icon-color: var(--color-blue);
  --mat-mdc-radio-checked-ripple-color: unset;
  --mat-mdc-radio-ripple-color: unset;
  --mdc-radio-disabled-unselected-icon-opacity: var(--color-opacity-30);
  --mdc-radio-disabled-selected-icon-opacity: var(--color-opacity-30);
}
mat-radio-button.mat-mdc-radio-button .mdc-form-field label, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-form-field label {
  font-size: 12px;
  letter-spacing: 0.0156em;
  line-height: 145%;
  font-family: var(--font-family-normal);
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-family-normal);
  font-weight: 400;
  text-transform: none;
  color: var(--color-grey-dark-9);
  padding-left: 0;
  cursor: pointer;
  padding-left: var(--space-s);
}
mat-radio-button.mat-mdc-radio-button .mdc-form-field .mdc-radio--disabled + label, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-form-field .mdc-radio--disabled + label {
  color: var(--color-primary-text-disabled);
}
mat-radio-button.mat-mdc-radio-button .mdc-form-field .mat-mdc-radio-touch-target, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-form-field .mat-mdc-radio-touch-target {
  transform: unset;
}
mat-radio-button.mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element {
  background-color: unset;
}
mat-radio-button.mat-mdc-radio-button .mdc-radio, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio {
  padding: unset;
  width: var(--space-m);
  height: var(--space-m);
}
mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background {
  width: var(--space-m);
  height: var(--space-m);
}
mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background .mdc-radio__inner-circle, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background .mdc-radio__inner-circle {
  border-width: var(--space-s);
}
mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  opacity: 0;
}
mat-radio-button.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus) ~ .mdc-radio__background::before, mat-radio-button.nv-mat-radio-button.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus) ~ .mdc-radio__background::before {
  opacity: 0;
}

:root {
  --color-white: #ffffff;
  --color-white-90: white;
  --color-white-80: white;
  --color-white-70: white;
  --color-white-60: white;
  --color-white-50: white;
  --color-white-40: white;
  --color-white-30: white;
  --color-white-20: white;
  --color-white-10: white;
  --color-white-5: white;
  --color-white-3: white;
  --color-white-1: white;
  --color-white-dark: #e6e6e6;
  --color-white-dark-80: #cccccc;
  --color-white-dark-70: #b3b3b3;
  --color-white-dark-60: #999999;
  --color-white-dark-50: gray;
  --color-black: #000000;
  --color-black-90: #1a1a1a;
  --color-black-80: #333333;
  --color-black-70: #4d4d4d;
  --color-black-60: #666666;
  --color-black-50: gray;
  --color-black-40: #999999;
  --color-black-30: #b3b3b3;
  --color-black-20: #cccccc;
  --color-black-10: #e6e6e6;
  --color-black-5: #f2f2f2;
  --color-black-3: #f7f7f7;
  --color-black-1: #fcfcfc;
  --color-black-dark: black;
  --color-black-dark-80: black;
  --color-black-dark-70: black;
  --color-black-dark-60: black;
  --color-black-dark-50: black;
  --color-red: rgb(194, 0, 24);
  --color-red-90: #c81a2f;
  --color-red-80: #ce3346;
  --color-red-70: #d44d5d;
  --color-red-60: #da6674;
  --color-red-50: #e1808c;
  --color-red-40: #e799a3;
  --color-red-30: #edb3ba;
  --color-red-20: #f3ccd1;
  --color-red-10: #f9e6e8;
  --color-red-5: #fcf2f3;
  --color-red-3: #fdf7f8;
  --color-red-1: #fefcfd;
  --color-red-dark: #af0016;
  --color-red-dark-80: #9b0013;
  --color-red-dark-70: #880011;
  --color-red-dark-60: #74000e;
  --color-red-dark-50: #61000c;
  --color-pink: rgb(255, 92, 143);
  --color-pink-90: #ff6c9a;
  --color-pink-80: #ff7da5;
  --color-pink-70: #ff8db1;
  --color-pink-60: #ff9dbc;
  --color-pink-50: #ffaec7;
  --color-pink-40: #ffbed2;
  --color-pink-30: #ffcedd;
  --color-pink-20: #ffdee9;
  --color-pink-10: #ffeff4;
  --color-pink-5: #fff7f9;
  --color-pink-3: #fffafc;
  --color-pink-1: #fffdfe;
  --color-pink-dark: #e65381;
  --color-pink-dark-80: #cc4a72;
  --color-pink-dark-70: #b34064;
  --color-pink-dark-60: #993756;
  --color-pink-dark-50: #802e48;
  --color-salmon: rgb(255, 137, 137);
  --color-salmon-90: #ff9595;
  --color-salmon-80: #ffa1a1;
  --color-salmon-70: #ffacac;
  --color-salmon-60: #ffb8b8;
  --color-salmon-50: #ffc4c4;
  --color-salmon-40: #ffd0d0;
  --color-salmon-30: #ffdcdc;
  --color-salmon-20: #ffe7e7;
  --color-salmon-10: #fff3f3;
  --color-salmon-5: #fff9f9;
  --color-salmon-3: #fffbfb;
  --color-salmon-1: #fffefe;
  --color-salmon-dark: #e67b7b;
  --color-salmon-dark-80: #cc6e6e;
  --color-salmon-dark-70: #b36060;
  --color-salmon-dark-60: #995252;
  --color-salmon-dark-50: #804545;
  --color-yellow: rgb(255, 196, 0);
  --color-yellow-90: #ffca1a;
  --color-yellow-80: #ffd033;
  --color-yellow-70: #ffd64d;
  --color-yellow-60: #ffdc66;
  --color-yellow-50: #ffe280;
  --color-yellow-40: #ffe799;
  --color-yellow-30: #ffedb3;
  --color-yellow-20: #fff3cc;
  --color-yellow-10: #fff9e6;
  --color-yellow-5: #fffcf2;
  --color-yellow-3: #fffdf7;
  --color-yellow-1: #fffefc;
  --color-yellow-dark: #e6b000;
  --color-yellow-dark-80: #cc9d00;
  --color-yellow-dark-70: #b38900;
  --color-yellow-dark-60: #997600;
  --color-yellow-dark-50: #806200;
  --color-green: rgb(0, 155, 72);
  --color-green-90: #1aa55a;
  --color-green-80: #33af6d;
  --color-green-70: #4db97f;
  --color-green-60: #66c391;
  --color-green-50: #80cda4;
  --color-green-40: #99d7b6;
  --color-green-30: #b3e1c8;
  --color-green-20: #ccebda;
  --color-green-10: #e6f5ed;
  --color-green-5: #f2faf6;
  --color-green-3: #f7fcfa;
  --color-green-1: #fcfefd;
  --color-green-dark: #008c41;
  --color-green-dark-80: #007c3a;
  --color-green-dark-70: #006d32;
  --color-green-dark-60: #005d2b;
  --color-green-dark-50: #004e24;
  --color-teal: rgb(10, 196, 172);
  --color-teal-90: #23cab4;
  --color-teal-80: #3bd0bd;
  --color-teal-70: #54d6c5;
  --color-teal-60: #6cdccd;
  --color-teal-50: #85e2d6;
  --color-teal-40: #9de7de;
  --color-teal-30: #b6ede6;
  --color-teal-20: #cef3ee;
  --color-teal-10: #e7f9f7;
  --color-teal-5: #f3fcfb;
  --color-teal-3: #f8fdfd;
  --color-teal-1: #fdfefe;
  --color-teal-dark: #09b09b;
  --color-teal-dark-80: #089d8a;
  --color-teal-dark-70: #078978;
  --color-teal-dark-60: #067667;
  --color-teal-dark-50: #056256;
  --color-blue: rgb(10, 74, 206);
  --color-blue-90: #235cd3;
  --color-blue-80: #3b6ed8;
  --color-blue-70: #5480dd;
  --color-blue-60: #6c92e2;
  --color-blue-50: #85a5e7;
  --color-blue-40: #9db7eb;
  --color-blue-30: #b6c9f0;
  --color-blue-20: #cedbf5;
  --color-blue-10: #e7edfa;
  --color-blue-5: #f3f6fd;
  --color-blue-3: #f8fafe;
  --color-blue-1: #fdfdff;
  --color-blue-dark: #0943b9;
  --color-blue-dark-80: #083ba5;
  --color-blue-dark-70: #073490;
  --color-blue-dark-60: #062c7c;
  --color-blue-dark-50: #052567;
  --color-navy: rgb(0, 1, 101);
  --color-navy-90: #1a1a74;
  --color-navy-80: #333484;
  --color-navy-70: #4d4d93;
  --color-navy-60: #6667a3;
  --color-navy-50: #8080b2;
  --color-navy-40: #9999c1;
  --color-navy-30: #b3b3d1;
  --color-navy-20: #cccce0;
  --color-navy-10: #e6e6f0;
  --color-navy-5: #f2f2f7;
  --color-navy-3: #f7f7fa;
  --color-navy-1: #fcfcfd;
  --color-navy-dark: #00015b;
  --color-navy-dark-80: #000151;
  --color-navy-dark-70: #000147;
  --color-navy-dark-60: #00013d;
  --color-navy-dark-50: #000133;
  --color-purple: rgb(102, 45, 145);
  --color-purple-90: #75429c;
  --color-purple-80: #8557a7;
  --color-purple-70: #946cb2;
  --color-purple-60: #a381bd;
  --color-purple-50: #b396c8;
  --color-purple-40: #c2abd3;
  --color-purple-30: #d1c0de;
  --color-purple-20: #e0d5e9;
  --color-purple-10: #f0eaf4;
  --color-purple-5: #f7f5fa;
  --color-purple-3: #faf9fc;
  --color-purple-1: #fdfdfe;
  --color-purple-dark: #5c2983;
  --color-purple-dark-80: #522474;
  --color-purple-dark-70: #472066;
  --color-purple-dark-60: #3d1b57;
  --color-purple-dark-50: #331749;
  --color-blue-05: var(--color-blue-5);
  --color-blue-03: var(--color-blue-3);
  --color-blue-01: var(--color-blue-1);
  --color-red-pill: --var(--color-pink);
  --color-white: #FFFFFF;
  --color-grey-dark-9: #09090B;
  --color-grey-dark-8: #18181B;
  --color-grey-dark-7: #27272A;
  --color-grey-dark-6: #3F3F46;
  --color-grey-dark-5: #52525B;
  --color-grey: #71717A;
  --color-grey-light-9: #A1A1AA;
  --color-grey-light-8: #D4D4D8;
  --color-grey-light-7: #E4E4E7;
  --color-grey-light-6: #F4F4F5;
  --color-grey-light-5: #FAFAFA;
  --color-blue-dark-9: #1D2758;
  --color-blue-dark-8: #2A3884;
  --color-blue-dark-7: #2E41A8;
  --color-blue-dark-6: #3850D2;
  --color-blue-dark-5: #3754EB;
  --color-blue: #4765FF;
  --color-blue-light-9: #7A8FFF;
  --color-blue-light-8: #A3B2FF;
  --color-blue-light-7: #BDC7FF;
  --color-blue-light-6: #E0E5FF;
  --color-blue-light-5: #F5F6FF;
  --color-red-dark-2: #991B1B;
  --color-red-dark-1: #B91C1C;
  --color-red: #DC2626;
  --color-red-light-1: #FECACA;
  --color-red-light-2: #F6EDED;
  --color-green-dark-2: #166534;
  --color-green-dark-1: #15803D;
  --color-green: #16A34A;
  --color-green-light-1: #BBF7D0;
  --color-green-light-2: #ECF5EF;
  --color-yellow-dark-2: #775C08;
  --color-yellow-dark-1: #C49608;
  --color-yellow: #F2C436;
  --color-yellow-light-1: #F4E1A4;
  --color-yellow-light-2: #FDF9ED;
  --color-purple-dark-1: #8A5DB6;
  --color-purple-light-1: #F4EFF8;
  --color-primary-text: var(--color-grey-dark-8);
  --color-primary-text-disabled: var(--color-grey-light-9);
  --color-standard-bar-blue: var(--color-blue-light-9);
  --color-blue-text: var(--color-blue);
  --color-blue-text-with-non-white-background: var(--color-blue-dark-5);
  --color-app-default-bg: var(--color-grey-light-5);
  --color-standard-bar-blue: #7A8FFF;
  --color-stacked-bar-seq-positive-5: #00494B;
  --color-stacked-bar-seq-positive-4: #076076;
  --color-stacked-bar-seq-positive-3: #076769;
  --color-stacked-bar-seq-positive-2: #3EA8A6;
  --color-stacked-bar-seq-positive-1: #BBE4D1;
  --color-stacked-bar-seq-neutral: #D4D4D8;
  --color-stacked-bar-seq-negative-1: #F0A882;
  --color-stacked-bar-seq-negative-2: #E47961;
  --color-stacked-bar-seq-negative-3: #C65154;
  --color-stacked-bar-seq-negative-4: #A52747;
  --color-stacked-bar-seq-negative-5: #81102D;
  --color-stacked-bar-cat-green: #2FB796;
  --color-stacked-bar-cat-blue: #516AEC;
  --color-stacked-bar-cat-yellow: #E7AD55;
  --color-stacked-bar-cat-purple: #8A5D86;
  --color-opacity-none: 0;
  --color-opacity-10: 0.1;
  --color-opacity-30: 0.3;
  --color-opacity-50: 0.5;
}

.mat-ripple .mat-ripple-element {
  background-color: var(--color-blue);
  opacity: 0.1;
}
.mat-ripple.mat-ripple-inverted.mat-ripple-element,
.mat-ripple.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.1;
}

*.red.mat-ripple .mat-ripple-element,
*.red .mat-ripple .mat-ripple-element {
  background-color: var(--color-red);
  opacity: 0.1;
}
*.red.mat-ripple-inverted.mat-ripple-element,
*.red.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

*.salmon.mat-ripple .mat-ripple-element,
*.salmon .mat-ripple .mat-ripple-element {
  background-color: var(--color-salmon);
  opacity: 0.1;
}
*.salmon.mat-ripple-inverted.mat-ripple-element,
*.salmon.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

*.pink.mat-ripple .mat-ripple-element,
*.pink .mat-ripple .mat-ripple-element {
  background-color: var(--color-pink);
  opacity: 0.1;
}
*.pink.mat-ripple-inverted.mat-ripple-element,
*.pink.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

*.yellow.mat-ripple .mat-ripple-element,
*.yellow .mat-ripple .mat-ripple-element {
  background-color: var(--color-yellow);
  opacity: 0.1;
}
*.yellow.mat-ripple-inverted.mat-ripple-element,
*.yellow.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

*.green.mat-ripple .mat-ripple-element,
*.green .mat-ripple .mat-ripple-element {
  background-color: var(--color-green);
  opacity: 0.1;
}
*.green.mat-ripple-inverted.mat-ripple-element,
*.green.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

*.teal.mat-ripple .mat-ripple-element,
*.teal .mat-ripple .mat-ripple-element {
  background-color: var(--color-teal);
  opacity: 0.1;
}
*.teal.mat-ripple-inverted.mat-ripple-element,
*.teal.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

*.navy.mat-ripple .mat-ripple-element,
*.navy .mat-ripple .mat-ripple-element {
  background-color: var(--color-navy);
  opacity: 0.1;
}
*.navy.mat-ripple-inverted.mat-ripple-element,
*.navy.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

*.purple.mat-ripple .mat-ripple-element,
*.purple .mat-ripple .mat-ripple-element {
  background-color: var(--color-purple);
  opacity: 0.1;
}
*.purple.mat-ripple-inverted.mat-ripple-element,
*.purple.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

*.blue.mat-ripple .mat-ripple-element,
*.blue .mat-ripple .mat-ripple-element {
  background-color: var(--color-blue);
  opacity: 0.1;
}
*.blue.mat-ripple-inverted.mat-ripple-element,
*.blue.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

*.black.mat-ripple .mat-ripple-element,
*.black .mat-ripple .mat-ripple-element {
  background-color: var(--color-black);
  opacity: 0.1;
}
*.black.mat-ripple-inverted.mat-ripple-element,
*.black.mat-ripple-inverted .mat-ripple-element {
  background-color: var(--color-white);
  opacity: 0.2;
}

.mdc-snackbar.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent;
  background: transparent;
  box-shadow: none;
}

/**
  * Reset font size and font color of mat-menu buttons to default prior to material v15
  * This applies when a button with the mat-menu-item directive is used. eg
  * <mat-menu>
  *   <button mat-menu-item>
  *   </buttton>
  * </mat-menu>
*/
button.mat-mdc-menu-item.mdc-list-item .mdc-list-item__primary-text, button.mat-mdc-menu-item.mdc-list-item .mat-mdc-menu-item-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

mat-spinner.mat-mdc-progress-spinner,
mat-progress-spinner.mat-mdc-progress-spinner {
  stroke-linecap: round;
  --mdc-circular-progress-active-indicator-color: var(--color-blue);
}
mat-spinner.mat-mdc-progress-spinner circle,
mat-progress-spinner.mat-mdc-progress-spinner circle {
  stroke: var(--color-blue);
}

.mat-expansion-panel.nv-mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background-color: var(--color-blue-light-5);
}

/************************************************
  RANGE SLIDER
************************************************/
/* usage
  component: ngx-slider

    <div class="nv-mat-range-slider">
        <form [formGroup]="rangeForm">
        <ngx-slider
            (userChangeEnd)="..."
            [options]="..."
            formControlName="..."
        ></ngx-slider>
        </form>
    </div>
*/
.nv-mat-range-slider .ngx-slider .ngx-slider-bar {
  background: var(--color-blue-light-6);
  height: 6px;
}

.nv-mat-range-slider .ngx-slider .ngx-slider-selection {
  background: var(--color-blue);
}

.nv-mat-range-slider .ngx-slider .ngx-slider-pointer {
  width: 12px;
  height: 12px;
  top: -3px;
  background-color: #fff;
  z-index: var(--z-idx-elevated);
  border-radius: 15px;
  box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
  outline-width: 0;
}

.nv-mat-range-slider .ngx-slider .ngx-slider-pointer::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 50%;
  border: 14px solid transparent;
  transform: translate(-50%, 0);
}

.nv-mat-range-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.nv-mat-range-slider .ngx-slider .ngx-slider-bubble {
  bottom: -35px;
  background-color: var(--color-blue);
  color: #fff;
  border: 1px solid var(--color-blue);
  border-radius: 5px;
  text-align: center;
  padding: 2px 6px;
  white-space: nowrap;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: var(--font-xxs);
}

.nv-mat-range-slider .ngx-slider .ngx-slider-bubble::before {
  content: "";
  position: absolute;
  top: -13px;
  left: 50%;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: inherit;
  transform: translate(-50%, 0);
}

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

/**
  * Each of the following custom level for angular material components sets all typography rules to the default except for $line-height and $letter-spacing:
  *
  * '$line-height: normal' and '$letter-spacing: normal' was the default prior to angular material 15. By creating the custom levels bellow, they are reset to normal
*/
html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #0a4ace;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #0a4ace;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #0a4ace;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0a4ace;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #0a4ace;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0a4ace;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #0a4ace;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0a4ace;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: var(--font-family-normal);
  --mat-option-label-text-line-height: normal;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: var(--font-family-normal);
  --mat-optgroup-label-text-line-height: normal;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: var(--font-family-normal);
  --mat-card-title-text-line-height: normal;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: var(--font-family-normal);
  --mat-card-subtitle-text-line-height: normal;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0a4ace;
  --mdc-linear-progress-track-color: rgba(10, 74, 206, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #0a4ace;
  --mdc-linear-progress-track-color: rgba(10, 74, 206, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: var(--font-family-normal);
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #0a4ace;
  --mdc-filled-text-field-focus-active-indicator-color: #0a4ace;
  --mdc-filled-text-field-focus-label-text-color: rgba(10, 74, 206, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #0a4ace;
  --mdc-outlined-text-field-focus-outline-color: #0a4ace;
  --mdc-outlined-text-field-focus-label-text-color: rgba(10, 74, 206, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(10, 74, 206, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #0a4ace;
  --mdc-filled-text-field-focus-active-indicator-color: #0a4ace;
  --mdc-filled-text-field-focus-label-text-color: rgba(10, 74, 206, 0.87);
  --mdc-outlined-text-field-caret-color: #0a4ace;
  --mdc-outlined-text-field-focus-outline-color: #0a4ace;
  --mdc-outlined-text-field-focus-label-text-color: rgba(10, 74, 206, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(10, 74, 206, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: var(--font-family-normal);
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: var(--font-family-normal);
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: var(--font-family-normal);
  --mat-form-field-container-text-line-height: normal;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: var(--font-family-normal);
  --mat-form-field-subscript-text-line-height: normal;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(10, 74, 206, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(10, 74, 206, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: var(--font-family-normal);
  --mat-select-trigger-text-line-height: normal;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: var(--font-family-normal);
  --mdc-dialog-subhead-line-height: normal;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: var(--font-family-normal);
  --mdc-dialog-supporting-text-line-height: normal;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #0a4ace;
  --mdc-chip-elevated-selected-container-color: #0a4ace;
  --mdc-chip-elevated-disabled-container-color: #0a4ace;
  --mdc-chip-flat-disabled-selected-container-color: #0a4ace;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #0a4ace;
  --mdc-chip-elevated-selected-container-color: #0a4ace;
  --mdc-chip-elevated-disabled-container-color: #0a4ace;
  --mdc-chip-flat-disabled-selected-container-color: #0a4ace;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: var(--font-family-normal);
  --mdc-chip-label-text-line-height: normal;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #0064b0;
  --mdc-switch-selected-handle-color: #0064b0;
  --mdc-switch-selected-hover-state-layer-color: #0064b0;
  --mdc-switch-selected-pressed-state-layer-color: #0064b0;
  --mdc-switch-selected-focus-handle-color: #003d90;
  --mdc-switch-selected-hover-handle-color: #003d90;
  --mdc-switch-selected-pressed-handle-color: #003d90;
  --mdc-switch-selected-focus-track-color: #4d98cd;
  --mdc-switch-selected-hover-track-color: #4d98cd;
  --mdc-switch-selected-pressed-track-color: #4d98cd;
  --mdc-switch-selected-track-color: #4d98cd;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #0064b0;
  --mdc-switch-selected-handle-color: #0064b0;
  --mdc-switch-selected-hover-state-layer-color: #0064b0;
  --mdc-switch-selected-pressed-state-layer-color: #0064b0;
  --mdc-switch-selected-focus-handle-color: #003d90;
  --mdc-switch-selected-hover-handle-color: #003d90;
  --mdc-switch-selected-pressed-handle-color: #003d90;
  --mdc-switch-selected-focus-track-color: #4d98cd;
  --mdc-switch-selected-hover-track-color: #4d98cd;
  --mdc-switch-selected-pressed-track-color: #4d98cd;
  --mdc-switch-selected-track-color: #4d98cd;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: var(--font-family-normal);
  --mdc-form-field-label-text-line-height: normal;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0a4ace;
  --mdc-radio-selected-hover-icon-color: #0a4ace;
  --mdc-radio-selected-icon-color: #0a4ace;
  --mdc-radio-selected-pressed-icon-color: #0a4ace;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #0a4ace;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0a4ace;
  --mdc-radio-selected-hover-icon-color: #0a4ace;
  --mdc-radio-selected-icon-color: #0a4ace;
  --mdc-radio-selected-pressed-icon-color: #0a4ace;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #0a4ace;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: var(--font-family-normal);
  --mdc-form-field-label-text-line-height: normal;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #0a4ace;
  --mdc-slider-focus-handle-color: #0a4ace;
  --mdc-slider-hover-handle-color: #0a4ace;
  --mdc-slider-active-track-color: #0a4ace;
  --mdc-slider-inactive-track-color: #0a4ace;
  --mdc-slider-with-tick-marks-inactive-container-color: #0a4ace;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #0a4ace;
  --mat-slider-hover-state-layer-color: rgba(10, 74, 206, 0.05);
  --mat-slider-focus-state-layer-color: rgba(10, 74, 206, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #0a4ace;
  --mat-slider-hover-state-layer-color: rgba(10, 74, 206, 0.05);
  --mat-slider-focus-state-layer-color: rgba(10, 74, 206, 0.2);
  --mdc-slider-handle-color: #0a4ace;
  --mdc-slider-focus-handle-color: #0a4ace;
  --mdc-slider-hover-handle-color: #0a4ace;
  --mdc-slider-active-track-color: #0a4ace;
  --mdc-slider-inactive-track-color: #0a4ace;
  --mdc-slider-with-tick-marks-inactive-container-color: #0a4ace;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mdc-slider-label-label-text-font: var(--font-family-normal);
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: normal;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: var(--font-family-normal);
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: normal;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0a4ace;
  --mdc-radio-selected-hover-icon-color: #0a4ace;
  --mdc-radio-selected-icon-color: #0a4ace;
  --mdc-radio-selected-pressed-icon-color: #0a4ace;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0a4ace;
  --mdc-radio-selected-hover-icon-color: #0a4ace;
  --mdc-radio-selected-icon-color: #0a4ace;
  --mdc-radio-selected-pressed-icon-color: #0a4ace;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0a4ace;
  --mdc-checkbox-selected-hover-icon-color: #0a4ace;
  --mdc-checkbox-selected-icon-color: #0a4ace;
  --mdc-checkbox-selected-pressed-icon-color: #0a4ace;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0a4ace;
  --mdc-checkbox-selected-hover-state-layer-color: #0a4ace;
  --mdc-checkbox-selected-pressed-state-layer-color: #0a4ace;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0a4ace;
  --mdc-checkbox-selected-hover-icon-color: #0a4ace;
  --mdc-checkbox-selected-icon-color: #0a4ace;
  --mdc-checkbox-selected-pressed-icon-color: #0a4ace;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0a4ace;
  --mdc-checkbox-selected-hover-state-layer-color: #0a4ace;
  --mdc-checkbox-selected-pressed-state-layer-color: #0a4ace;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #0a4ace;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #0a4ace;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: var(--font-family-normal);
  --mdc-list-list-item-label-text-line-height: normal;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: var(--font-family-normal);
  --mdc-list-list-item-supporting-text-line-height: normal;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: var(--font-family-normal);
  --mdc-list-list-item-trailing-supporting-text-line-height: normal;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / normal var(--font-family-normal);
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mat-paginator-container-text-font: var(--font-family-normal);
  --mat-paginator-container-text-line-height: normal;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0a4ace;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0a4ace;
  --mat-tab-header-active-ripple-color: #0a4ace;
  --mat-tab-header-inactive-ripple-color: #0a4ace;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0a4ace;
  --mat-tab-header-active-hover-label-text-color: #0a4ace;
  --mat-tab-header-active-focus-indicator-color: #0a4ace;
  --mat-tab-header-active-hover-indicator-color: #0a4ace;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #0a4ace;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0a4ace;
  --mat-tab-header-active-ripple-color: #0a4ace;
  --mat-tab-header-inactive-ripple-color: #0a4ace;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0a4ace;
  --mat-tab-header-active-hover-label-text-color: #0a4ace;
  --mat-tab-header-active-focus-indicator-color: #0a4ace;
  --mat-tab-header-active-hover-indicator-color: #0a4ace;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #0a4ace;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #0a4ace;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: var(--font-family-normal);
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: normal;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0a4ace;
  --mdc-checkbox-selected-hover-icon-color: #0a4ace;
  --mdc-checkbox-selected-icon-color: #0a4ace;
  --mdc-checkbox-selected-pressed-icon-color: #0a4ace;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0a4ace;
  --mdc-checkbox-selected-hover-state-layer-color: #0a4ace;
  --mdc-checkbox-selected-pressed-state-layer-color: #0a4ace;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0a4ace;
  --mdc-checkbox-selected-hover-icon-color: #0a4ace;
  --mdc-checkbox-selected-icon-color: #0a4ace;
  --mdc-checkbox-selected-pressed-icon-color: #0a4ace;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0a4ace;
  --mdc-checkbox-selected-hover-state-layer-color: #0a4ace;
  --mdc-checkbox-selected-pressed-state-layer-color: #0a4ace;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: var(--font-family-normal);
  --mdc-form-field-label-text-line-height: normal;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0a4ace;
  --mat-text-button-state-layer-color: #0a4ace;
  --mat-text-button-ripple-color: rgba(10, 74, 206, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #0a4ace;
  --mat-text-button-state-layer-color: #0a4ace;
  --mat-text-button-ripple-color: rgba(10, 74, 206, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0a4ace;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #0a4ace;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0a4ace;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #0a4ace;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0a4ace;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #0a4ace;
  --mat-outlined-button-ripple-color: rgba(10, 74, 206, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #0a4ace;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #0a4ace;
  --mat-outlined-button-ripple-color: rgba(10, 74, 206, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: var(--font-family-normal);
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: var(--font-family-normal);
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: var(--font-family-normal);
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: var(--font-family-normal);
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0a4ace;
  --mat-icon-button-state-layer-color: #0a4ace;
  --mat-icon-button-ripple-color: rgba(10, 74, 206, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #0a4ace;
  --mat-icon-button-state-layer-color: #0a4ace;
  --mat-icon-button-ripple-color: rgba(10, 74, 206, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #0a4ace;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #0a4ace;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #0a4ace;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #0a4ace;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: var(--font-family-normal);
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #0a4ace;
}

html {
  --mdc-snackbar-supporting-text-font: var(--font-family-normal);
  --mdc-snackbar-supporting-text-line-height: normal;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: var(--font-family-normal);
  --mat-table-header-headline-line-height: normal;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: var(--font-family-normal);
  --mat-table-row-item-label-text-line-height: normal;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: var(--font-family-normal);
  --mat-table-footer-supporting-text-line-height: normal;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #0a4ace;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #0a4ace;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #0a4ace;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #0a4ace;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: var(--font-family-normal);
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: var(--font-family-normal);
  --mat-bottom-sheet-container-text-line-height: normal;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: var(--font-family-normal);
  --mat-legacy-button-toggle-label-text-line-height: normal;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: var(--font-family-normal);
  --mat-standard-button-toggle-label-text-line-height: normal;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #0a4ace;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(10, 74, 206, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(10, 74, 206, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(10, 74, 206, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #0a4ace;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(10, 74, 206, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #0a4ace;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(10, 74, 206, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(10, 74, 206, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(10, 74, 206, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(10, 74, 206, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #0a4ace;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: var(--font-family-normal);
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: var(--font-family-normal);
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: var(--font-family-normal);
  --mat-expansion-container-text-line-height: normal;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #0a4ace;
}
.mat-icon.mat-accent {
  --mat-icon-color: #0a4ace;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #0a4ace;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #0a4ace;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #0a4ace;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #0a4ace;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #0a4ace;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #0a4ace;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: var(--font-family-normal);
  --mat-stepper-header-label-text-font: var(--font-family-normal);
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #0a4ace;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #0a4ace;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: var(--font-family-normal);
  --mat-toolbar-title-text-line-height: normal;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: var(--font-family-normal);
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

/*=============================================
=            SCROLL BAR            =
=============================================*/
::-webkit-scrollbar {
  width: var(--scroll-bar);
  height: var(--scroll-bar);
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-color);
  border-radius: var(--space-s);
  border: var(--space-xxs) solid white;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

html {
  color: var(--color-primary-text);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family-normal);
}

* code {
  font-family: var(--font-family-mono);
}

.unformatted-link {
  color: inherit;
  text-decoration: inherit;
  height: 100%;
  width: 100%;
}