//
// These should match the colors in app.theme.js. We keep them here as well for use in custom SCSS.
//

//nvGray Use these colors for background shades
//DO NOT USE FOR TYPOGRAPHY
$nv-grey-50: #d5d9de;
$nv-grey-100: #c7ccd3;
$nv-grey-200: #b9bfc8;
$nv-grey-300: #abb2bc;
$nv-grey-400: #9ca5b1;
$nv-grey-500: #8e98a6;
$nv-grey-600: #808b9b;
$nv-grey-700: #717e90;
$nv-grey-800: #667181;
$nv-grey-900: #5b6573;
$nv-grey-A100: #e4e6e9;
$nv-grey-A200: #f2f3f5;
$nv-grey-A400: #ffffff;
$nv-grey-A700: #505865;
$nv-background-hue-0: $nv-grey-500;
$nv-background-hue-1: $nv-grey-300;
$nv-background-hue-2: $nv-grey-800;
$nv-background-hue-3: $nv-grey-A100;

//nvRed use these colors for warnings
$nv-red-50: #f07f83;
$nv-red-100: #ed686d;
$nv-red-200: #eb5257;
$nv-red-300: #e83b41;
$nv-red-400: #e6242b;
$nv-red-500: #d71920;
$nv-red-600: #c0161d;
$nv-red-700: #a91419;
$nv-red-800: #921116;
$nv-red-900: #7c0e12;
$nv-red-A100: #f3969a;
$nv-red-A200: #f5adb0;
$nv-red-A400: #f8c4c6;
$nv-red-A700: #650c0f;
$nv-warn-hue-0: $nv-red-500;
$nv-warn-hue-1: $nv-red-300;
$nv-warn-hue-2: $nv-red-800;
$nv-warn-hue-3: $nv-red-A100;

//nvBlue
$nv-blue-50: #e2e9f9;
$nv-blue-100: #b6c9f0;
$nv-blue-200: #85a5e7;
$nv-blue-300: #5480dd;
$nv-blue-400: #2f65d5;
$nv-blue-500: #0a4ace;
$nv-blue-600: #0943c9;
$nv-blue-700: #073ac2;
$nv-blue-800: #0532bc;
$nv-blue-900: #0322b0;
$nv-blue-A100: #dadeff;
$nv-blue-A200: #a7b2ff;
$nv-blue-A400: #7485ff;
$nv-blue-A700: #5a6fff;
$nv-blue-hue-0: $nv-blue-500;
$nv-blue-hue-1: $nv-blue-300;
$nv-blue-hue-2: $nv-blue-800;
$nv-blue-hue-3: $nv-blue-A100;

//nvYellow
$nv-yellow-50: #573e00;
$nv-yellow-100: #705000;
$nv-yellow-200: #8a6200;
$nv-yellow-300: #a37400;
$nv-yellow-400: #bd8700;
$nv-yellow-500: #d69900;
$nv-yellow-600: #ffb90a;
$nv-yellow-700: #ffc024;
$nv-yellow-800: #ffc73d;
$nv-yellow-900: #ffcf57;
$nv-yellow-A100: #ffb90a;
$nv-yellow-A200: #f0ab00;
$nv-yellow-A400: #d69900;
$nv-yellow-A700: #ffd670;
$nv-yellow-hue-0: $nv-yellow-500;
$nv-yellow-hue-1: $nv-yellow-300;
$nv-yellow-hue-2: $nv-yellow-800;
$nv-yellow-hue-3: $nv-yellow-A100;

//nvOrange - used in step indicators
$nv-orange-100: #ffa500;

// Material Design Theme. Update these if you update app.theme.js
$color-md-primary: $nv-blue-hue-0;

// Colors for on track status
// TODO: update these colors with right hex values
$nv-on-track-status: #009b48;
$nv-needs-support-status: #d81920;
$nv-college-not-eligible-track-status: $nv-needs-support-status; //TODO: get rid of this
$nv-almost-on-track-status: #efab00;
$nv-college-ready-track-status: #0070c3;
$nv-card-background: #f8f8f8;

// Colors for ssv boxes
$nv-box-green: #008000;
$nv-box-light-green: #4cc94c;
$nv-box-red: #ff3333;
$nv-box-light-red: #ff3333;
$nv-box-purple: #9370db;
$nv-box-light-purple: mediumpurple;
$nv-box-blue: #0000e1;
$nv-box-light-blue: #66aded;
$nv-box-gray: $nv-grey-300;
$nv-box-medium-gray: $nv-grey-500;
$nv-box-dark-gray: $nv-grey-900;
$nv-box-orange: orange;
$nv-mat-card-background: var(--color-blue-03);

// Colors for credit gaps tables
$nv-credit-gaps-red: rgb(228, 149, 156);
$nv-credit-gaps-pink: rgb(254, 199, 191);
$nv-credit-gaps-orange: rgb(255, 225, 185);
$nv-credit-gaps-green: rgb(187, 218, 163);
$nv-credit-gaps-yellow: rgb(255, 239, 184);

// Colors for borders and UI separators
$nv-rule-color: rgba(0, 0, 0, 0.12);

// Colors for tables
$nv-row-hover: $nv-background-hue-3;

// Color for warning-icon in tooltip-warning
$nv-warning-red: #ff0000;

//COLORS
//TODO - if we move towards generic Angular Material palette, we can deprecate some of these vars.

// TODO: these are deprecated. See nvColors.scss, or use one of these Angular Material classes:
// md-primary - New Visions Blue
//    md-hue-1 - light blue
//    md-hue-2 - medium blue
//    md-hue-3 - dark blue
// md-accent - New Visions Yellow
//    md-hue-1 to md-hue 3 (same as above)
// md-warn - New Visions Orange
//    md-hue-1 to md-hue-3 (same as above)
$color-red: #d71920;
$color-white: #ffffff;
$color-blue: #006cb7;
$color-bluish: #006ab0;
$color-purple: #652c90;
$color-green: #009b48;
$color-yellow: #f0ab00;
$button-active: #ff735c;
$color-gray: #8e98a6;
$color-light-gray: #d5d5d5;
$color-light-gray-2: #f8f8f9;
$color-light-gray-3: #eeeeee;
$color-light-gray-4: rgba(158, 158, 158, 0.2);
$color-lighter-gray: #f5f5f5;
$color-black-body-default: rgba(0, 0, 0, 0.87);
$color-black-40: rgba(0, 0, 0, 0.4);
$color-black-54: rgba(0, 0, 0, 0.54);
$color-nv-inputs: rgba(0, 0, 0, 0.12);

//COLORS - MD OVERRIDES
$color-md-button-default-hover: rgba(158, 158, 158, 0.2);
