/* 

We should not have global styles that are component specific defined here.
Either move your styles to a more specific component,
or remove them from this repo, and use them in our design library "src/nvps-libraries/design"
- AT

*/

%nv-checkbox {
  padding: 0;
  justify-content: center;
  margin-left: var(--space-xs);

  .checkbox {
    height: var(--space-l);
    width: var(--space-l);
    display: inline-block;
    position: relative;
    border-radius: var(--space-xxs);
    background-image: url(/assets/v3/img/checkbox-off.png);
    background-position: center;
    background-size: contain;
    vertical-align: middle;

    &.checkbox-selected {
      background-image: url(/assets/v3/img/checkbox-on.png);
    }
  }
}

// TODO-jchu: add the mat version checkbox
// %nv-mat-checkbox {
// ..
// }

%nv-mat-form-field {
  width: 100%;

  .mdc-line-ripple {
    display: none;
  }

  .mat-form-field-label {
    color: var(--color-blue-50);
  }

  &.mat-form-field-invalid,
  &.mat-focused {
    .mat-mdc-input-element {
      caret-color: var(--color-blue);
    }

    .mat-form-field-label {
      color: var(--color-blue-50);
    }
  }
}

%nv-textbox {
  background-color: var(--color-blue-03);
  border: var(--space-xxxs) solid var(--color-blue-10);
  border-radius: var(--space-xs);
  color: var(--color-blue);
  font-size: var(--font-s);
  margin: var(--space-xs) 0px;
  outline: 0;
  overflow: hidden;
  padding: var(--space-s) var(--space-m);
  position: relative;
  &::placeholder {
    color: var(--color-blue-50);
  }
}

%nv-textbox-search {
  @extend %nv-textbox;
  background-size: var(--space-l);
  background-image: url('/assets/v3/img/icon-search-30.png');
  background-repeat: no-repeat;
  background-position: var(--space-s) center;
  padding-left: var(--space-xxl);
  &:focus {
    background-image: none;
    box-shadow: var(--elevation-1);
    padding-left: var(--space-m);
  }
}

%nv-mat-menu-panel {
  button {
    line-height: var(--space-l);
    font-size: var(--font-xs);
    font-weight: 500;
    letter-spacing: var(--space-xxxs);
    display: block;
    padding: var(--space-s) var(--space-m) var(--space-s) var(--space-m);
    margin-right: var(--space-m) !important;
    cursor: pointer;
    background-color: white;
    background-repeat: no-repeat;
    background-position: var(--space-s) center;
    background-size: var(--space-l);
    // the following selectors are material buttons children classes. A lot of specificity is needed to overrive their styles
    &.mat-mdc-menu-item.mdc-list-item {
      span.mdc-list-item__primary-text, span.mat-mdc-menu-item-text {
        color: var(--color-blue);
        line-height: var(--space-l);
        font-size: var(--font-xs);
        font-weight: 500;
        letter-spacing: var(--space-xxxs);
      }
    }
  }

  .active,
  .active:hover {
    background-color: var(--color-blue);
    color: #fff;
  }

  &.mat-mdc-menu-panel {
    background-color: white;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
  }

  .mat-mdc-menu-item {
    min-width: 180px;
  }
  .mat-mdc-menu-item:hover:not(.active) {
    background-color: var(--color-blue-05);
  }
}

// TODO-jchu: add the autocomplete option panel
// %nv-autocomplete-option-panel {
//   // ..
// }

%nv-meta-flag {
  border-radius: 4px;
  background-color: var(--color-blue);
  color: var(--color-white);
  font-size: var(--font-xxs);
  line-height: 24px;
  text-transform: uppercase;
  padding: 0 2px;
  text-align: center;
}
